import React from "react";
import { MinusSmallIcon } from "@heroicons/react/20/solid";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { PATH_CONTACT } from "../utils/constants";
import { SEOHelmet } from "../components/common/SEOHelmet";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { LogoSvg } from "../components/svgs/LogoSvg";

interface FAQ {
  question: string;
  answer: string;
}

interface Section {
  title: string;
  faqs: FAQ[];
}

const data: Section = {
  title: "Häufig gestellte Fragen",
  faqs: [
    {
      question: "Wie erhalte ich Ergotherapie?",
      answer:
        "Ergotherapie ist eine Heilmittelbehandlung. Sie benötigen eine Heilmittelverordnung (=Rezept), welches vom Hausarzt:In, Neurologen:In, Psychiater:In, Psychologen:In, Neuropsychologe:In oder Kinderarzt:In ausgestellt wird. In der Regel tragen die gesetzlichen Krankenkassen den größten Teil der Kosten. Wenn Sie gebührenbefreit sind, übernimmt die gesetzliche Krankenkasse die Kosten der kompletten Heilmittelbehandlung. Wenn dies nicht der Fall ist, müssen Versicherte 10% der Behandlungskosten und eine Rezeptgebühr von 10€ selbst tragen. Sie werden jedoch ausführlich zu Beginn der Behandlung informiert.",
    },
    {
      question: "Wann ist Ergotherapie sinnvoll?",
      answer:
        "Ergotherapie ist immer dann sinnvoll, wenn die Handlungsfähigkeit im Alltag eingeschränkt ist. Es kann beispielsweise sein, dass man durch eine Erkrankung Einschränkungen hat und bestimmte Funktionen wieder beüben möchte, beispielsweise nach einem Schlaganfall. Ergotherapie ist jedoch auch sehr sinnvoll bei psychischen Erkrankungen. Es gibt viele Klienten:Innen, die Veränderungen bei sich bemerken, beispielsweise innere Unruhe, Schlafstörungen, Konzentrationsstörungen etc., jedoch erst einmal alleine versuchen wollen, alles in den Griff zu bekommen. Es kann helfen, mit einem Experten:In problematische Situationen zu besprechen, um gemeinsam Strategien und Lösungen zu erarbeiten. Auch bei Kindern kann Ergotherapie sehr sinnvoll sein. Viele Eltern fühlen sich alleine gelassen, wissen nicht mehr, wie sie mit ihrem Kind umgehen sollen, weil es aggressiv oder ablehnend reagiert. Manche Kinder ziehen sich mehr und mehr zurück, entwickeln Ängste oder Zwänge. Auch hier kann die ergotherapeutische Behandlung ansetzen. Eltern/Bezugspersonen werden mit in den Therapieprozess einbezogen.",
    },
    {
      question:
        "Warum orientiert sich die Ergotherapie am Alltag der Klienten:Innen?",
      answer:
        "Die Ergotherapie betrachtet den Menschen ganzheitlich. Alle Menschen sind in Systeme eingebunden (Familie, Arbeit/Schule/Freunde etc.), deshalb ist es wichtig auch dort anzusetzen. Bestimmte Krankheitsbilder/Verhaltensweisen können durch die Therapeutin erklärt werden. Es ist sehr wichtig, dass ein Verständnis der Angehörigen/Bezugspersonen vorhanden ist, nur dann ist nachvollziehbar, warum sich der Klient:In in bestimmten Situationen so verhält, wie er/sie es tut. Außerdem ist es wichtig, dass sich Angehörige/Bezugspersonen nicht hilflos und allein gelassen fühlen, sondern wissen, dass die Therapeuten:Innen auch Ansprechpartner:Innen sind, wenn es um die Belange des Klienten:In geht.",
    },
    {
      question: "Wann ist ein Hausbesuch sinnvoll?",
      answer:
        "Ein Hausbesuch ist sinnvoll bei Klienten:Innen, die aufgrund ihrer eingeschränkten Mobilität nicht in der Lage sind, in die Räumlichkeiten der Praxis zu kommen. Liegt der Fokus der Therapieziele auf bestimmten Alltagstätigkeiten wie beispielsweise kochen etc., könnte es ebenfalls sinnvoll sein, dies mit den Klienten:Innen in ihren eigenen vier Wänden durchzuführen. Die Heilmittelverordnung darf nur als Hausbesuch durch den behandelnden Arzt:In verordnet werden.",
    },
    {
      question:
        "Wieso liegt der Fokus der Praxis auch bei psychischen Erkrankungen auf der Alltagsorientierung und nicht auf dem Handwerk?",
      answer:
        "Ergotherapie hat sehr viel Potential. Es geht darum Schwierigkeiten aus dem Leben der Klienten:Innen aufzugreifen und gezielt miteinander zu bearbeiten. Mögliche Bereiche, an denen angesetzt werden kann, sind beispielsweise das Steigern/Verbessern des Selbstwertgefühls/Selbstbewusstseins, Erarbeiten einer Tagesstrukturierung, Aufbau eines sozialen Netzwerks, Findung von Hobbys/Interessen, Verbesserung der Kommunikationsfähigkeiten, Bewältigung alltäglicher Aufgaben etc. Es gibt sehr viele Bereiche, die für unser tägliches Leben sehr wichtig sind. Es ist bedeutsam an Schwierigkeiten zu arbeiten, damit sich Veränderungen erzielen lassen. Gerade im ambulanten Bereich der Ergotherapie sollte der Alltag der Klienten:Innen immer im Fokus stehen, denn nur so wird eine größtmögliche Handlungsfähigkeit im Leben der Klienten:Innen erzielt. Und das ist das Ziel der Ergotherapie.",
    },
    {
      question:
        "Wieso gibt es die Möglichkeit im Vorfeld ein kostenloses 15-minütiges Telefongespräch zu führen?",
      answer:
        "Aufkommende Fragen können dann gleich zu Beginn in einem Telefonat besprochen und geklärt werden. Wenn ein Angehöriger eine Heilmittelverordnung vom Arzt:In erhalten hat oder man selbst eine Behandlung beginnen möchte, ist es wichtig, dass die Möglichkeit besteht, sich vorab Informationen einholen zu können.",
    },
    {
      question: "Wie läuft ein Anamnesegespräch (Aufnahmegespräch) ab?",
      answer:
        "Gerne dürfen Sie zum Anamnesegespräch Angehörige oder eine enge Bezugsperson mitbringen. Zu Beginn werden in der Regel erst ein Mal alle formalen Dinge geklärt. Danach geht es darum, einen Einblick in Ihre Abläufe zu erhalten. Was gefällt Ihnen, was machen Sie gerne? Erhalten Sie Unterstützung durch Familie/Freunde/Bekannte etc.? Danach werden die Dinge angesprochen, die Ihnen schwer fallen. Bestenfalls können Sie bereits im Aufnahmegespräch Therapieziele benennen, Dinge, die Sie innerhalb der Therapie erreichen möchten.",
    },
    {
      question:
        "Warum werden Angehörige/Bezugspersonen in die Therapie einbezogen?",
      answer:
        "Selbstverständlich erfolgt eine Einbindung von Angehörigen/Bezugspersonen nur im Einverständnis der Klienten:In. Eine Einbindung ist sehr sinnvoll, denn so erhalten auch Angehörige/Bezugspersonen einen Einblick in die Therapie, aufkommende Fragen können geklärt werden, Strategien und Lösungen können gemeinsam erarbeitet und umgesetzt werden. Angehörige/Bezugspersonen können durch die Therapiestunden lernen, wie sie in bestimmten Situationen besser mit dem Klienten:In umgehen können.",
    },
  ],
};

export default function FAQsPage() {
  return (
    <>
      <SEOHelmet page="faqs" />

      <main className="space-y-16">
        {/* FAQ Header Section */}
        <section className="mx-auto text-center" aria-labelledby="faq-heading">
          <div className="flex flex-col items-center">
            <LogoSvg classNames="h-16 w-auto fill-current text-ergo-500 dark:text-ergo-400 mb-8" aria-label="Ergotherapie Rottweil Logo" />
            <h1 id="faq-heading" className="text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-12 drop-shadow-sm">
              Häufig gestellte Fragen
            </h1>
            <div className="prose prose-lg max-w-none dark:prose-invert">
              <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                Falls Sie weitere Fragen haben, können Sie mich gerne{" "}
                <a href={PATH_CONTACT} className="text-ergo-600 hover:text-ergo-500 dark:text-ergo-400 dark:hover:text-ergo-300 no-underline">
                  kontaktieren
                </a>
                .
              </p>
            </div>
          </div>
        </section>

        {/* FAQ List Section */}
        <section aria-label="FAQ Liste" className="mx-auto max-w-3xl">
          <div className="space-y-4">
            {data.faqs.map((faq, index) => (
              <Disclosure as="div" key={faq.question}>
                {({ open }) => (
                  <div className="bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-100 dark:border-gray-700/60 transition-all duration-200 hover:border-ergo-100 dark:hover:border-ergo-800">
                    <Disclosure.Button className="flex w-full items-center justify-between px-6 py-4 text-left group hover:bg-gray-50/50 dark:hover:bg-gray-800/50 transition-colors duration-300">
                      <span className="text-lg font-medium text-gray-900 dark:text-white pr-8 group-hover:text-ergo-600 dark:group-hover:text-ergo-400 transition-colors duration-300">
                        {faq.question}
                      </span>
                      <span className="flex-shrink-0">
                        <ChevronDownIcon
                          className={`h-6 w-6 text-gray-400 group-hover:text-ergo-400 dark:text-gray-500 dark:group-hover:text-ergo-400 transition-all duration-300 ${
                            open ? "rotate-180" : ""
                          }`}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel static>
                      <div 
                        className={`transition-all duration-300 ease-in-out ${
                          open ? "opacity-100 max-h-[2000px] pb-6" : "opacity-0 max-h-0 overflow-hidden"
                        }`}
                      >
                        <div className="px-6">
                          <div className="prose prose-lg max-w-none dark:prose-invert">
                            <p className="text-gray-600 dark:text-gray-300">{faq.answer}</p>
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
          </div>
        </section>
      </main>
    </>
  );
}
