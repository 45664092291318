import React from "react";
import { Testimonial, TestimonialsComponent } from "../components/common/testimonial/TestimonialComponent";
import { SEOHelmet } from "../components/common/SEOHelmet";

type Section = {
  title: string;
  content: string[];
  texts?: string[];
  img?: string;
  ratio?: string;
};

const data: { sections: Section[]; testimonials: Testimonial[] } = {
  sections: [
    {
      title: "Neurologie / Geriatrie",
      content: [
        "Neurologische Erkrankungen:",
        "Schlaganfälle/Blutungen",
        "neurologische Störungen bedingt durch Tumore oder Traumata",
        "Multiple Sklerose",
        "ALS",
        "Morbus Parkinson",
        "Demenz",
        "Kognitive Defizite",
        "Post-Covid",
      ],
      img: process.env.PUBLIC_URL + "/images/neurology.jpg",
      ratio: "aspect-[3/4]",
      texts: [
        "Nach einem Schicksalsschlag wie beispielsweise einem Schlaganfall ist nichts mehr wie es war. Ergotherapie setzt genau da an. Wir unterstützen und begleiten Sie, greifen auf , was Ihnen schwerfällt und erarbeiten gemeinsame Therapieziele. Mögliche Fragen könnten sein: Was ist Ihnen wichtig? Was möchten Sie im Alltag wieder  können? Welche Unterstützungsmöglichkeiten gibt es?",
        "Angehörige oder enge Bezugspersonen können mit in die Therapie einbezogen werden. Selbstverständlich wird dafür im Vorfeld das Einverständnis des Klienten:In eingeholt.",
      ],
    },
    {
      title: "Was beinhaltet eine sensomotorisch-perzeptive Behandlung?",
      content: [
        "Verbesserung der Grob- und Feinmotorik",
        "Verbesserung von alltäglichen Verrichtungen (z.B. Zähne putzen, anziehen etc.)",
        "Verbesserung der Sensomotorik (Tonusregulation, Anbahnen physiologischer Bewegungen, Koordination von Bewegungsabläufen)",
        "Erarbeiten der individuellen Grundmobilität",
        "Sensibilitätstraining",
        "Propriozeptive Neuromuskuläre Fazilitation bei Gesichtslähmung",
        "Graphomotorisches Training",
      ],
    },
    {
      title: "Was wird bei einem Hirnleistungstraining beübt?",
      content: [
        "Verbesserung der Konzentrations- und Aufmerksamkeitsfähigkeit",
        "Erarbeiten von Gedächtnisstrategien",
        "Einbeziehung von Angehörigen/nahen Bezugspersonen, um auf Hilfestellung/Einbindung im häuslichen Umfeld hinzuweisen",
        "Beratungen bezüglich Gefahren im Alltag, Hinweis auf unterstützende Organisationen",
        "Erhaltung und Verbesserung der Merkfähigkeit, Leistungen im auditiven, sowie im visuellen Bereich",
        "Schulung der Orientierungsfähigkeit (zeitl., örtl., persönl., situativ)",
      ],
    },
    {
      title: "Was beinhaltet ein ADL-Training?",
      content: [
        "Wasch- und Anziehtraining",
        "Nahrungsaufnahme",
        "Haushaltstraining",
        "Hilfsmittelberatung und Hilfsmittelversorgung",
      ],
    },
    {
      title: "Was noch berücksichtigt werden sollte?",
      content: [
        "Stärkung und Förderung des Selbstwertgefühls",
        "Verbesserung der sozio-emotionalen Fähigkeiten",
        "Stärkung im Bereich: Kommunikationsfähigkeiten",
        "Umgang innerhalb der Partnerschaft",
        "Umgang mit dem Thema Sexualität",
        "Kontinenz",
        "Angehörigenberatung",
      ],
    },
  ],
  testimonials: [
    {
      quote:
        "Dank der Ergotherapie bin ich wieder in der Lage, meine betroffene Hand besser zu spüren. Ich kann wieder ein Wasserglas halten und selbständig trinken.",
    },
    {
      quote:
        "In der Praxis habe ich wertvolle Tipps erhalten, um mit der Demenz meines Mannes besser umgehen zu können.Ich kann auch als Angehörige auftretende Probleme innerhalb der Therapie einbringen, damit  gemeinsam Lösungen erarbeitet werden können.",
    },
  ],
};

export default function NeurologyGeriatricsPage() {
  return (
    <>
      <SEOHelmet page="neurologie" />

      <main className="space-y-16">
        {data.sections.map((section, index) => (
          <article key={index}>
            {index === 0 ? (
              // First section with image
              <section className="grid lg:grid-cols-2 gap-12 items-start">
                <div>
                  <h1 className="text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-12 drop-shadow-sm">
                    Neurologische und Geriatrische Ergotherapie
                  </h1>
                  <h2 className="sr-only">Behandlungsschwerpunkte</h2>
                  <ul
                    className="list-disc marker:text-ergo-400 dark:marker:text-ergo-500 list-inside space-y-3 mb-8 text-lg text-gray-600 dark:text-gray-300"
                    aria-label="Neurologische Erkrankungen"
                  >
                    {section.content.map((item, index) => (
                      <li key={index} className="text-gray-600 dark:text-gray-300 leading-relaxed">
                        {item}
                      </li>
                    ))}
                  </ul>
                  {section.texts?.map((text, index) => (
                    <p key={index} className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6">
                      {text}
                    </p>
                  ))}
                </div>
                {section.img && (
                  <aside className="lg:sticky lg:top-8">
                    <img
                      src={section.img}
                      alt="Neurologische und Geriatrische Ergotherapie - Professionelle Behandlung"
                      className={`w-64 md:w-96 lg:w-124 rounded-2xl object-cover shadow-md dark:shadow-xl dark:shadow-black/20 ${
                        section.ratio || ""
                      }`}
                    />
                  </aside>
                )}
              </section>
            ) : (
              // Treatment sections
              <section aria-labelledby={`section-${index}`}>
                <h2
                  id={`section-${index}`}
                  className="text-2xl sm:text-3xl font-semibold tracking-tight text-ergo-600 dark:text-ergo-400 mb-8 drop-shadow-sm"
                >
                  {section.title}
                </h2>
                <ul className="list-disc marker:text-ergo-400 dark:marker:text-ergo-500 list-inside space-y-3 mb-8 text-lg text-gray-600 dark:text-gray-300">
                  {section.content.map((item, index) => (
                    <li key={index} className="text-gray-600 dark:text-gray-300 leading-relaxed">
                      {item}
                    </li>
                  ))}
                </ul>
                {section.texts?.map((text, index) => (
                  <p key={index} className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6">
                    {text}
                  </p>
                ))}
              </section>
            )}
          </article>
        ))}

        {/* Testimonials Section */}
        <section aria-label="Erfahrungsberichte">
          <h2 className="sr-only">Erfahrungsberichte unserer Klienten</h2>
          <TestimonialsComponent testimonials={data.testimonials} />
        </section>
      </main>
    </>
  );
}
