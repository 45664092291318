import React from "react";
import { CalendarIcon, MapPinIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { calculateYears } from "../utils/utils";
import { useHref, useNavigate } from "react-router-dom";
import OfficeLocationComponent from "../components/common/OfficeLocationComponent";
import { SEOHelmet } from "../components/common/SEOHelmet";

// Types
interface Training {
  title: string;
  location?: string;
  date: string;
}

interface TrainingCategory {
  categoryName?: string;
  trainings: Training[];
}

interface Highlight {
  title: string;
  location?: string;
  date?: string;
  ratio?: string;
  image?: string;
  href?: string;
}

interface TeamMemberPhotoProps {
  src: string;
  name: string;
  brightness?: string;
}

interface TeamMemberSectionProps {
  name: string;
  imageSrc: string;
  content: string;
  trainings: TrainingCategory[];
  brightness?: string;
}

const highlights: Highlight[] = [
  {
    title:
      "Autorin der Broschüre: Liebe, Lust und Leidenschaft, Sexualität nach Schlaganfall (für die Stiftung der Deutschen Schlaganfall-Hilfe)",
    location: "Gütersloh",
    date: "2020",
    ratio: "aspect-[4/5]",
    image: process.env.PUBLIC_URL + "/Sexualität_nach_Schlaganfall_Titel.jpg",
  },
  // {
  //   title:
  //     "Ausbildung zur Sexualberaterin in klinischer Sexologie nach Sexocorporel",
  //   location: "Kißlegg",
  //   date: "2017 - 2019",
  //   ratio: "aspect-[4/5]",
  // },
];

const trainingsMeyer: TrainingCategory[] = [
  {
    categoryName: "Neurologie",
    trainings: [
      {
        title: "Energiemanagement-Schulung für Menschen mit Fatigue (EMS)",
        location: "Valens",
        date: "",
      },
      {
        title: "Post Covid im ambulanten Setting",
        date: "02.11.24",
      },
      {
        title:
          "Rottweiler Herbstsymposium für Psychiatrie, Psychotherapie und Psychosomatik im Vinzenz von Paul Hospital",
        location: "Rottweil",
        date: "09.11.14",
      },
      {
        title: "Neurotraining nach Verena Schweizer",
        location: "Zürich",
        date: "18.11/19.11.24",
      },
      {
        title: "Hilfsmittelversorgung",
        location: "Albstadt",
        date: "05.12.24",
      },
    ],
  },
  {
    categoryName: "Psychiatrie",
    trainings: [
      {
        title: "IPT Kompaktkurs",
        location: "Freiburg",
        date: "20.06-22.06.24",
      },
      {
        title:
          "Rottweiler Herbstsymposium für Psychiatrie, Psychotherapie und Psychosomatik im Vinzenz von Paul Hospital",
        location: "Rottweil",
        date: "09.11.14",
      },
    ],
  },
];

const trainingsFroehlich: TrainingCategory[] = [
  {
    categoryName: "Handtherapie",
    trainings: [
      {
        title: "Evidenzbasierte Hand- und Armbehandlung in der Neurologie",
        date: "15.07.24",
      },
      {
        title: "CRPS (Mb.Sudeck) ganzheitlich behandeln",
        date: "10.07.24",
      },
      {
        title: "Narbentherapie",
        date: "Juli 2024",
      },
    ],
  },
  {
    categoryName: "Pädiatrie",
    trainings: [
      {
        title: "Spielideen zur Förderung von Körperbewusstsein und Wahrnehmung",
        date: "10.04.24",
      },
      {
        title: "Den Selbstwert von Kindern und Jugendlichen stärken",
        date: "11.04.24",
      },
      {
        title: "Weiterbildung zum Coach für psychische Gesundheit für Kinder und Jugendliche",
        date: "21.10-24.10.24",
      },
    ],
  },
];

const trainings: TrainingCategory[] = [
  {
    categoryName: "Neurologie/Geriatrie",
    trainings: [
      {
        title: "Energiemanagement-Schulung für Menschen mit Fatigue (EMS)",
        location: "Karlsruhe",
        date: "05.07-06.07.2024",
      },
      {
        title: "Hospizliche Begleitung und palliative Pflege sterbenskranker Menschen",
        location: "Rottweil",
        date: "22.02.2017",
      },
      {
        title: "Pflege nach Bobath-Lagern und positionieren im Bett",
        location: "Rottweil",
        date: "15.03.2016",
      },
      {
        title: "Einführung in die F.O.T.T",
        location: "Burgau",
        date: "03.03-04.03.2016",
      },
      {
        title: "Special Edition Neurologie",
        location: "Bochum",
        date: "06.-07.11.2015",
      },
      {
        title: "LIN- Lagerung in Neutralnullstellung",
        location: "Burgau",
        date: "16.-18.10.2015",
      },
      {
        title: "Befundung und Behandlung von Hemiplegiepatienten mit schmerzhafter Schulter",
        location: "Reutlingen",
        date: "17.07-18.07.2015",
      },
      {
        title:
          "Rottweiler Herbstsymposium für Psychiatrie, Psychotherapie und Psychosomatik im Vinzenz von Paul Hospital",
        location: "Rottweil",
        date: "09.11.2014",
      },
      { title: "Gelenksymposium", location: "Leipzig", date: "08.03.2014" },
      {
        title: "Teilhaben- (neuro)logisch!",
        location: "Stuttgart",
        date: "26.01.2014",
      },
      {
        title: "Hemiparese: Behandlung der oberen Extremität",
        location: "Reutlingen",
        date: "30.11 - 02.12. 2013",
      },
      {
        title: "Bobath- 24- Stunden-Konzept",
        location: "Reutlingen",
        date: "28.11-30.11.2013",
      },
      {
        title: "Bobath Workshop Multiple Sklerose",
        location: "Kassel",
        date: "11.11-15.11.2013",
      },
      {
        title: "HoDT (Handlungsorientierte Diagnostik und Therapie)",
        location: "Berlin",
        date: "13.-19.10.2014",
      },
      {
        title: "Aufgabenorientiertes Training: posturale Kontrolle",
        location: "Allensbach",
        date: "19.10.2013",
      },
    ],
  },
  {
    categoryName: "Psychiatrie",
    trainings: [
      {
        title: "„Curriculum zur Interpersonellen Psychotherapie (IPT) - Kompaktkurs: Einführung und Aufbau“",
        location: "Freiburg",
        date: "27.04-29.04.2017",
      },
      {
        title:
          "Psychotherapie Fernlehrgang zur Vorbereitung auf die amtsärztliche Prüfung nach dem Heilpraktikergesetz",
        location: "Institut für Lernsysteme",
        date: "14.03.2016-14.06.2017",
      },
      {
        title: "Ergotherapie von Menschen mit Schizophrenie und wahnhaften Störungen",
        location: "Marburg",
        date: "12.-13.12.2014",
      },
      {
        title: "25.Rottweiler Herbstsymposium",
        location: "Rottweil",
        date: "12.11.2016",
      },
      { title: "Delir", location: "Rottweil", date: "12.10.2016" },
      {
        title: "Deeskalationstraining",
        location: "Rottweil",
        date: "14./15./28./29.09.2015",
      },
    ],
  },
  {
    categoryName: "Pädiatrie",
    trainings: [
      {
        title: "Basiswissen Autismus",
        date: "19.10.2024",
      },
      {
        title: "Spielideen zur Förderung von Körperbewusstsein und Wahrnehmung",
        date: "10.04.2024",
      },
      {
        title: "Kindernotfalltraining",
        location: "Steinenbronn",
        date: "06.09.2019",
      },
      {
        title: "TLS in der therapeutischen Praxis",
        location: "Bellheim",
        date: "11.06-15.06.2014",
      },
      {
        title: "Präventive Ergotherapie an Grundschulen",
        location: "Mannheim",
        date: "14.09.2013",
      },
    ],
  },
  {
    categoryName: "Sexualität",
    trainings: [
      {
        title: "Zwei jährige Weiterbildung: Sexualtherapie in klinischer Sexologie nach Sexocorporel",
        location: "Kißlegg",
        date: "2017- 2019",
      },
      {
        title: "Multiplikatoren Schulung zum Thema sexualisierte Gewalt in Einrichtungen",
        location: "Untermachtal",
        date: "2022",
      },
    ],
  },
  {
    categoryName: "Zusatzqualifikationen",
    trainings: [
      {
        title: "Betätigungsorientierte Ergotherapie",
        location: "Stuttgart",
        date: "21.03.2015",
      },
      {
        title: "Besprechungen in Teams -Ergebnisorientiert Führen",
        location: "Rottweil",
        date: "20.02.2017",
      },
      {
        title: "Einführung in das „Ergotherapeutische Assessment“",
        location: "Karlsbad-Langensteinbach",
        date: "14.-16.03.2011",
      },
    ],
  },
];

// Components
const TrainingCard: React.FC<Training> = ({ title, location, date }) => (
  <div className="group hover:scale-[1.02] transition-all duration-500 ease-out rounded-xl shadow-sm hover:shadow-md bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm p-5 border border-gray-100 dark:border-gray-700/60">
    <h3 className="text-base leading-snug font-medium text-gray-800 dark:text-gray-200 group-hover:text-ergo-600 dark:group-hover:text-ergo-400 transition-colors duration-300">
      {title}
    </h3>
    <div className="mt-2 flex text-xs text-gray-500 dark:text-gray-400">
      {location && <p className="leading-4 after:content-[','] after:mr-1">{location}</p>}
      <p className="leading-4">{date}</p>
    </div>
  </div>
);

const TrainingCategoryComponent: React.FC<TrainingCategory> = ({ categoryName, trainings }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const title = categoryName;

  return (
    <div className="border-b border-gray-100 dark:border-gray-700/60 last:border-0">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between py-5 text-left group hover:bg-gray-50/50 dark:hover:bg-gray-800/50 
          transition-colors duration-300 rounded-lg px-4 -mx-4"
      >
        <div className="flex items-baseline gap-4">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 group-hover:text-ergo-600 dark:group-hover:text-ergo-400 transition-colors duration-300">
            {title}
          </h3>
          <span className="text-xs font-medium text-gray-400 group-hover:text-ergo-400 dark:group-hover:text-ergo-300 transition-colors duration-300">
            {trainings.length} {trainings.length === 1 ? "Eintrag" : "Einträge"}
          </span>
        </div>
        <ChevronDownIcon
          className={`h-5 w-5 text-gray-400 group-hover:text-ergo-400 dark:group-hover:text-ergo-300 transition-all duration-300 ${
            isExpanded ? "rotate-180" : ""
          }`}
        />
      </button>
      <div
        className={`transition-all duration-300 ease-in-out ${
          isExpanded ? "opacity-100 max-h-[2000px] pb-8" : "opacity-0 max-h-0 overflow-hidden"
        }`}
      >
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 pt-2">
          {trainings.map((training, index) => (
            <TrainingCard key={index} {...training} />
          ))}
        </div>
      </div>
    </div>
  );
};

const HighlightCard: React.FC<Highlight> = ({ title, location, date, ratio, image, href }) => (
  <div
    className="group hover:scale-[1.02] transition-all duration-500 ease-out rounded-xl shadow-sm hover:shadow-md 
      bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm border border-gray-100 dark:border-gray-700/60 overflow-hidden cursor-pointer"
    onClick={() => href && window.open(href, "_blank")}
  >
    {image && (
      <div className="aspect-[4/3] overflow-hidden">
        <img
          className="w-full h-full object-cover transition-transform duration-700 ease-out group-hover:scale-105"
          src={image}
          alt={title}
        />
      </div>
    )}
    <div className="p-5">
      <h3 className="text-base leading-snug font-medium text-gray-800 dark:text-gray-200 group-hover:text-ergo-600 dark:group-hover:text-ergo-400 transition-colors duration-300">
        {title}
      </h3>
      <div className="mt-2 flex text-xs text-gray-500 dark:text-gray-400">
        {location && <p className="leading-4 after:content-[','] after:mr-1">{location}</p>}
        <p className="leading-4">{date}</p>
      </div>
    </div>
  </div>
);

const TeamMemberPhoto: React.FC<TeamMemberPhotoProps> = ({ src, name, brightness = "" }) => (
  <div className="group relative transform hover:-translate-y-2 transition-transform duration-300">
    <div className="aspect-[3/4] rounded-2xl overflow-hidden shadow-md dark:shadow-xl dark:shadow-black/20 bg-white dark:bg-gray-800 max-w-[300px]">
      <img
        src={process.env.PUBLIC_URL + src}
        alt={name}
        className={`w-full h-full object-cover transition-transform duration-500 group-hover:scale-105 ${brightness}`}
      />
    </div>
    <h3 className="mt-4 text-center">
      <span className="text-gray-900 dark:text-white font-playfair italic text-base tracking-wide">{name}</span>
    </h3>
  </div>
);

const TeamMemberSection: React.FC<TeamMemberSectionProps> = ({
  name,
  imageSrc,
  content,
  trainings,
  brightness = "",
}) => (
  <div className="mt-16 grid lg:grid-cols-2 gap-12 items-start">
    <div>
      <h3 className="text-2xl sm:text-3xl font-semibold tracking-tight text-gray-900 dark:text-white mb-8 drop-shadow-sm">
        {name}
      </h3>
      <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6">{content}</p>
    </div>
    <div className="lg:sticky lg:top-8">
      <img
        src={process.env.PUBLIC_URL + imageSrc}
        alt={name}
        className={`w-64 md:w-96 lg:w-124 rounded-2xl object-cover shadow-md dark:shadow-xl dark:shadow-black/20 ${brightness}`}
      />
    </div>
    <div className="lg:col-span-2 -mt-6">
      <div className="divide-y divide-gray-200 dark:divide-gray-700/60">
        {trainings.map((category, index) => (
          <TrainingCategoryComponent key={index} {...category} />
        ))}
      </div>
    </div>
  </div>
);

const PromotionBanner: React.FC<{ href: string }> = ({ href }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="block group -mt-8">
    <div
      className="bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm border border-gray-100 dark:border-gray-700/60 rounded-2xl p-5 
      hover:border-ergo-200 dark:hover:border-ergo-700 transition-all duration-300 shadow-sm hover:shadow-md"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="rounded-xl bg-ergo-50 dark:bg-ergo-900/80 p-3">
            <svg
              className="w-6 h-6 text-ergo-600 dark:text-ergo-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              />
            </svg>
          </div>
          <div>
            <p className="text-sm font-medium text-gray-600 dark:text-gray-300">Mehr zu Sexualität auf</p>
            <p className="text-lg font-semibold text-ergo-600 dark:text-ergo-400">sx-med.de</p>
          </div>
        </div>
        <svg
          className="w-5 h-5 text-gray-400 group-hover:text-ergo-600 dark:group-hover:text-ergo-400 transition-colors duration-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </div>
  </a>
);

export default function AboutPage() {
  return (
    <>
      <SEOHelmet page="about" />

      <article className="space-y-16">
        {/* Team Overview Section */}
        <header>
          <h1 className="text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-12 drop-shadow-sm">
            Unser Team der Ergotherapie Rottweil
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-12">
            Unser Team bei Ergotherapie Rottweil besteht aus erfahrenen Ergotherapeutinnen, die sich leidenschaftlich
            für die ganzheitliche Betreuung ihrer Klienten einsetzen und durch ihre individuellen Stärken und
            Fachgebiete eine umfassende Versorgung gewährleisten.
          </p>

          {/* Team Photos Grid */}
          <section className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 items-center">
            <TeamMemberPhoto src="/images/uns.jpeg" name="Team" />
            <TeamMemberPhoto src="/images/lisa.png" name="Lisa Spreitzer" brightness="brightness-125" />
            <TeamMemberPhoto src="/images/sandra_meyer.jpeg" name="Sandra Meyer" />
            <TeamMemberPhoto src="/images/charlotte_froehlich.jpeg" name="Charlotte Fröhlich" />
          </section>
        </header>

        {/* Individual Team Member Sections */}
        <section className="px-6">
          <h2 className="sr-only">Team Mitglieder Details</h2>

          {/* Lisa Spreitzer Section */}
          <article>
            <TeamMemberSection
              name="Lisa Spreitzer"
              imageSrc="/images/lisa.png"
              brightness="brightness-125"
              content={`Mein Name ist Lisa Spreitzer, ich bin verheiratet und Mutter von zwei Kindern. Seit ${calculateYears(
                "04/01/2013"
              )} Jahren arbeite ich leidenschaftlich als Ergotherapeutin, wobei mir besonders der Umgang mit Menschen jeden Alters Freude bereitet. In meiner langjährigen Praxis habe ich vor allem mit neurologischen, geriatrischen und psychiatrischen Klienten gearbeitet. Dabei ist es mir wichtig, dass die Therapie praxisnah ist und sich am Alltag orientiert. Gemeinsam entwickeln wir Strategien, um Ihnen zu helfen, Ihren Alltag besser zu bewältigen. Auch bei Kindern und Jugendlichen ist es entscheidend, frühzeitig an problematischen Bereichen zu arbeiten, um psychische Auffälligkeiten zu vermeiden.`}
              trainings={trainings}
            />
          </article>

          {/* Highlights Section */}
          <section className="space-y-6">
            <h3 className="sr-only">Highlights und Publikationen</h3>
            <div className="mx-auto">
              <PromotionBanner href="https://www.sx-med.de" />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {highlights.map((highlight, index) => (
                <HighlightCard key={index} {...highlight} />
              ))}
            </div>
          </section>

          {/* Sandra Meyer Section */}
          <article>
            <TeamMemberSection
              name="Sandra Meyer"
              imageSrc="/images/sandra_meyer.jpeg"
              content="Als Ergotherapeutin ist es mein Ziel, traditionelle Ansätze zu überwinden und Therapien gemäß den neuesten Erkenntnissen und wissenschaftlichen Studien durchzuführen. Ich lege großen Wert darauf, mit meinen Klientinnen und Klienten ganzheitlich und praxisnah zu arbeiten, insbesondere in den Bereichen Psychiatrie und Neurologie. Während eines dreimonatigen Praktikums in den Kliniken Valens, Schweiz, konnte ich die beeindruckenden Ergebnisse des Energiemanagements kennenlernen. Ab sofort biete ich in unserer Praxis in Kleingruppen mit maximal fünf Teilnehmerinnen und Teilnehmern Schulungen zum Energiemanagement an. Diese Gruppenarbeit bietet eine hervorragende Möglichkeit für Personen, die unter ständiger Müdigkeit und Erschöpfung leiden und sich dauerhaft ausgelaugt und kraftlos fühlen, ihre Lebensqualität zu verbessern."
              trainings={trainingsMeyer}
            />
          </article>

          {/* Charlotte Fröhlich Section */}
          <article>
            <TeamMemberSection
              name="Charlotte Fröhlich"
              imageSrc="/images/charlotte_froehlich.jpeg"
              content="Ich sehe die vielfältigen Möglichkeiten der Ergotherapie, Menschen ganzheitlich zu behandeln, ihre Stärken zu fördern und gemeinsam schwierige Situationen anzugehen. Meine Fachgebiete liegen insbesondere in den Bereichen Psychiatrie und Pädiatrie. Ich leite sowohl Kinder- als auch Elterngruppen, um Transparenz, Anleitung und ganzheitliche Unterstützung zu bieten. Ein Schwerpunkt meiner Arbeit ist das THOP-Kindertraining und THOP-Elterntraining, spezialisiert auf Kinder mit hyperkinetischem und oppositionellem Verhalten. Mir liegt es am Herzen, Kinder frühzeitig in ihrem Selbstwertgefühl und Selbstbewusstsein zu stärken und ihnen zu vermitteln, dass sie für sich selbst eintreten dürfen. Durch meine eigene Erfahrung im Kickboxen in meiner Jugend biete ich einen Selbstwert-Kurs für Kinder im Alter von 5 bis 8 Jahren an."
              trainings={trainingsFroehlich}
            />
          </article>
        </section>
      </article>
    </>
  );
}
