import React from "react";
import { SEOHelmet } from "../components/common/SEOHelmet";
import { TestimonialsComponent } from "../components/common/testimonial/TestimonialComponent";

type OfferSection = {
  title: string;
  content: string[];
  details?: {
    label: string;
    value: string;
  }[];
  texts?: string[];
  isMainSection?: boolean;
};

const data: { sections: OfferSection[] } = {
  sections: [
    {
      title: "Weitere Angebote",
      content: [],
      texts: [
        "Neben der klassischen Ergotherapie bieten wir auch weitere Schulungen und Kurse an. Hier finden Sie eine Übersicht über die aktuellen Angebote.",
      ],
      isMainSection: true,
    },
    {
      title: "Energiemanagement Schulung",
      content: [
        "Individuelles Erstgespräch, fünf Gruppenlektionen zu Themen wie Pausenmanagement und wirkungsvolle Kommunikation, individuelles Einzelgespräch zum Thema \"meine Ziele im Alltag\", begleitendes Selbsttraining für zu Hause",
      ],
      details: [
        {
          label: "Wer",
          value: "Klienten:Innen mit Fatigue, z.B. nach Post-Covid Erkrankung, Krebserkrankung, Schlaganfall, Schädel-Hirn-Trauma, Morbus Parkinson, Multiple Sklerose, Narkolepsie",
        },
        {
          label: "Kosten",
          value: "Mögliche Kostenübernahme durch die Krankenkasse",
        },
        {
          label: "Angeboten von",
          value: "Sandra Meyer",
        },
      ],
      isMainSection: true,
    },
    {
      title: "Selbstwert-Kurs für Kinder",
      content: [
        "Stärkung des Selbstbewusstseins durch Wahrnehmung und Respektierung des eigenen Körpers und der eigenen Grenzen, Übungen zum selbstbewussten Auftreten, Prävention gefährlicher Situationen, Selbstverteidigungstechniken",
      ],
      details: [
        {
          label: "Wer",
          value: "Kinder im Alter von 5-8 Jahren",
        },
        {
          label: "Kosten",
          value: "80€ für acht Einheiten",
        },
        {
          label: "Angeboten von",
          value: "Charlotte Fröhlich",
        },
      ],
      isMainSection: true,
    },
    {
      title: "Handtherapie",
      content: [
        "Rheumatische Erkrankungen (z.B. Arthritis)",
        "Verletzungen der Sehnen, Bänder, Gelenkkapseln und Knochen",
        "Karpaltunnelsyndrom, M. Duypuytren",
        "Morbus Sudeck",
        "Vor- und Nachbehandlung von Operationen",
        "Postoperativen Narben",
      ],
      texts: [
        "Eine Handtherapie kommt dann zum Tragen, wenn die Funktionen der Hand durch eine Schädigung in ihrer Funktionalität beeinträchtigt ist. Durch kleinste Verletzungen der Hand, kann es bereits zu gravierenden Einschränkungen im Alltag und oder Berufsleben kommen. Je nach Art und Verletzung werden unterschiedliche ergotherapeutische Maßnahmen ergriffen, um die Funktionalität der Hand wieder herzustellen und/oder zu verbessern.",
      ],
      isMainSection: true,
    },
    {
      title: "Was beinhaltet eine motorisch- funktionelle Behandlung?",
      content: [
        "Narbenbehandlung (professionelle Nachsorge durch entsprechende Pflege und Massage der Narbe)",
        "Verbesserung der Feinmotorik und der manuellen Geschicklichkeit",
        "Schmerzreduktion",
        "Wiederaufnahme von Alltagstätigkeiten oder das Erlernen von Kompensationsstrategien",
        "Erlernen von Gelenkschutzmaßnahmen zur Reduzierung von schmerzbedingten Reaktionen",
        "Verbesserung der Sensibilität",
        "Verbesserung der Beweglichkeit, der Muskelkraft, der Koordination, der Ausdauer und der Belastbarkeit",
        "Thermische Anwendungen (Wärme/Kälte) z.B. Vorbereitung und Unterstützung von aktiver und/oder passiver Mobilisation",
        "Taping = funktionelle Verbandstechnik, Gelenke können dadurch stabilisiert werden und vor weiteren Traumatisierungen geschützt werden.",
      ],
    },
    {
      title: "Was noch berücksichtigt werden sollte?",
      content: [
        "Psychische Stabilisierung durch begleitende Gespräche (z.B. zur Krankheitsverarbeitung)",
        "Nach Möglichkeit: Übungen für ein Eigentraining zu Hause",
        "Eventuell Einbeziehung von Angehörigen",
        "ggf. Arbeitsplatzgestaltung zur Schmerzreduzierung",
      ],
    },
  ],
};

const testimonials = [
  {
    quote:
      "Mein Morbus Sudeck hat mein Leben ganz schön auf den Kopf gestellt. Dank der Ergotherapie kann ich meine Hand wieder im Alltag einsetzen. Auch die Gespräche über meine Ängste und Sorgen haben mir sehr gut getan.",
    section: "Handtherapie"
  },
];

export default function OtherOffersPage() {
  return (
    <>
      <SEOHelmet page="otherOffers" />

      <main className="space-y-16">
        {/* Main sections */}
        {data.sections.map((section, index) => (
          <article key={index}>
            {section.isMainSection ? (
              // Main sections with full content
              <section aria-labelledby={`section-${index}`}>
                <h1
                  id={`section-${index}`}
                  className="text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-12 drop-shadow-sm"
                >
                  {section.title}
                </h1>
                <div className="prose prose-lg max-w-none dark:prose-invert">
                  <ul
                    className="list-disc marker:text-ergo-400 dark:marker:text-ergo-500 list-inside space-y-3 mb-8 text-lg text-gray-600 dark:text-gray-300"
                    aria-label={`Leistungen und Details zu ${section.title}`}
                  >
                    {section.content.map((item, index) => (
                      <li key={index} className="text-gray-600 dark:text-gray-300 leading-relaxed">
                        {item}
                      </li>
                    ))}
                  </ul>
                  {section.texts?.map((text, index) => (
                    <p key={index} className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-8">
                      {text}
                    </p>
                  ))}
                  {section.details && (
                    <aside
                      className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                      aria-label={`Zusätzliche Informationen zu ${section.title}`}
                    >
                      {section.details.map((detail, idx) => (
                        <div
                          key={idx}
                          className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-xl p-6 border border-ergo-100/60 dark:border-gray-700
                            shadow-sm hover:shadow-md transition-all duration-300 group"
                        >
                          <dt className="text-sm uppercase tracking-wider text-gray-500 dark:text-gray-400 font-medium mb-2">
                            {detail.label}
                          </dt>
                          <dd className="text-gray-700 dark:text-gray-200 font-medium leading-relaxed">
                            {detail.value}
                          </dd>
                        </div>
                      ))}
                    </aside>
                  )}
                  {/* Show testimonial if it belongs to this section */}
                  {section.title === "Handtherapie" && (
                    <div className="mt-12">
                      <TestimonialsComponent testimonials={testimonials} />
                    </div>
                  )}
                </div>
              </section>
            ) : (
              // Supporting sections
              <section aria-labelledby={`section-${index}`}>
                <h2
                  id={`section-${index}`}
                  className="text-2xl sm:text-3xl font-semibold tracking-tight text-ergo-600 dark:text-ergo-400 mb-8 drop-shadow-sm"
                >
                  {section.title}
                </h2>
                <div className="prose prose-lg max-w-none dark:prose-invert">
                  <ul
                    className="list-disc marker:text-ergo-400 list-inside space-y-3 mb-8 text-lg text-gray-600 dark:text-gray-300"
                    aria-label={`Details zu ${section.title}`}
                  >
                    {section.content.map((item, index) => (
                      <li key={index} className="text-gray-600 dark:text-gray-300 leading-relaxed">
                        {item}
                      </li>
                    ))}
                  </ul>
                  {section.texts?.map((text, index) => (
                    <p key={index} className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6">
                      {text}
                    </p>
                  ))}
                </div>
              </section>
            )}
          </article>
        ))}
      </main>
    </>
  );
}
