interface SEOData {
    title: string;
    description: string;
    ogTitle: string;
    ogDescription: string;
    keywords: string;
}

export const SEO_DATA: Record<string, SEOData> = {
    start: {
        title: "Ergotherapie Rottweil - Ganzheitliche und individuelle Therapie",
        description: "Ihre Ergotherapie-Praxis in Rottweil. Spezialisiert auf Neurologie, Psychiatrie und ganzheitliche Therapie. ✓ Barrierefrei ✓ Zentrale Lage ✓ Individuelle Betreuung",
        ogTitle: "Ergotherapie Rottweil - Ihre Praxis für ganzheitliche Therapie",
        ogDescription: "Professionelle Ergotherapie in Rottweil: Neurologie, Psychiatrie, Geriatrie und Pädiatrie. Individuelle Therapien für Ihren Alltag.",
        keywords: "Ergotherapie, Rottweil, Neurologie, Psychiatrie, Therapie, Rehabilitation, Behandlung, Königstraße",
    },
    neurologie: {
        title: "Neurologie Ergotherapie Rottweil - Neurologische Rehabilitation",
        description: "Spezialisierte neurologische Ergotherapie in Rottweil. ✓ Schlaganfall ✓ Multiple Sklerose ✓ Parkinson ✓ Individuelle Therapiekonzepte für neurologische Erkrankungen",
        ogTitle: "Neurologische Ergotherapie Rottweil - Experten für neurologische Rehabilitation",
        ogDescription: "Professionelle neurologische Ergotherapie: Behandlung nach Schlaganfall, bei MS, Parkinson und anderen neurologischen Erkrankungen. Individuelle Therapiekonzepte.",
        keywords: "Neurologische Ergotherapie, Schlaganfall, Multiple Sklerose, Parkinson, Neurologie, Rottweil, Rehabilitation, neurologische Erkrankungen",
    },
    psychiatrie: {
        title: "Psychiatrische Ergotherapie Rottweil - Psychische Gesundheit",
        description: "Psychiatrische Ergotherapie in Rottweil. ✓ Depression ✓ Burnout ✓ Angststörungen ✓ Ganzheitliche Therapieansätze für psychische Erkrankungen",
        ogTitle: "Psychiatrische Ergotherapie Rottweil - Kompetente Begleitung bei psychischen Erkrankungen",
        ogDescription: "Erfahrene psychiatrische Ergotherapie bei Depression, Burnout, Angststörungen und anderen psychischen Erkrankungen. Individuelle und ganzheitliche Behandlung.",
        keywords: "Psychiatrische Ergotherapie, Depression, Burnout, Angststörungen, Psychiatrie, Rottweil, psychische Gesundheit, mentale Gesundheit",
    },
    geriatrie: {
        title: "Geriatrische Ergotherapie Rottweil - Therapie im Alter",
        description: "Geriatrische Ergotherapie in Rottweil. ✓ Altersbedingte Einschränkungen ✓ Demenz ✓ Sturzprophylaxe ✓ Selbstständigkeit im Alter fördern",
        ogTitle: "Geriatrische Ergotherapie Rottweil - Kompetente Therapie für Senioren",
        ogDescription: "Spezialisierte geriatrische Ergotherapie für mehr Lebensqualität im Alter. Behandlung bei Demenz, Sturzprophylaxe und altersbedingten Einschränkungen.",
        keywords: "Geriatrische Ergotherapie, Demenz, Sturzprophylaxe, Senioren, Alter, Rottweil, Selbstständigkeit, Lebensqualität",
    },
    paediatrie: {
        title: "Pädiatrische Ergotherapie Rottweil - Therapie für Kinder",
        description: "Kindgerechte Ergotherapie in Rottweil. ✓ ADHS ✓ Entwicklungsverzögerungen ✓ Lernstörungen ✓ Spielerische Förderung der Entwicklung",
        ogTitle: "Pädiatrische Ergotherapie Rottweil - Spielerisch Entwicklung fördern",
        ogDescription: "Kindgerechte ergotherapeutische Behandlung bei ADHS, Entwicklungsverzögerungen und Lernstörungen. Spielerische und individuelle Therapiekonzepte.",
        keywords: "Pädiatrische Ergotherapie, ADHS, Entwicklungsverzögerung, Lernstörungen, Kinder, Rottweil, Entwicklungsförderung, Motorik",
    },
    otherOffers: {
        title: "Weitere Angebote - Ergotherapie Rottweil | Kurse & Schulungen",
        description: "Entdecken Sie unsere zusätzlichen Angebote: ✓ Energiemanagement Schulung ✓ Selbstwert-Kurs für Kinder ✓ Individuelle Beratung ✓ Professionelle Schulungen",
        ogTitle: "Weitere Angebote der Ergotherapie Rottweil - Kurse & Schulungen",
        ogDescription: "Spezielle Kurse und Schulungen: Energiemanagement bei Fatigue, Selbstwert-Kurse für Kinder und weitere ergotherapeutische Angebote in Rottweil.",
        keywords: "Ergotherapie Kurse, Energiemanagement, Fatigue, Selbstwert-Kurs, Kinder, Schulungen, Rottweil, Post-Covid, Zusatzangebote",
    },
    about: {
        title: "Über uns - Ergotherapie Rottweil | Ihr erfahrenes Therapeuten-Team",
        description: "Lernen Sie unser qualifiziertes Team der Ergotherapie Rottweil kennen. ✓ Langjährige Erfahrung ✓ Individuelle Betreuung ✓ Ganzheitlicher Ansatz",
        ogTitle: "Über uns - Ihr Ergotherapie-Team in Rottweil",
        ogDescription: "Erfahren Sie mehr über unser Team, unsere Philosophie und unseren ganzheitlichen Therapieansatz. Wir sind für Sie da - persönlich und kompetent.",
        keywords: "Ergotherapie Team, Therapeuten Rottweil, Ergotherapeuten, Praxisteam, Qualifikationen, Therapeutische Erfahrung, Ganzheitliche Therapie",
    },
    ergotherapie: {
        title: "Ergotherapie Rottweil - Ganzheitliche Therapie & Behandlung",
        description: "Professionelle Ergotherapie in Rottweil. ✓ Alltagsorientiert ✓ Individuell angepasst ✓ Evidenzbasierte Behandlungsmethoden",
        ogTitle: "Ergotherapie Rottweil - Ihre Praxis für ganzheitliche Behandlung",
        ogDescription: "Erfahren Sie mehr über unsere ergotherapeutischen Behandlungsmethoden. Wir bieten individuelle und alltagsorientierte Therapien für alle Altersgruppen.",
        keywords: "Ergotherapie, Rottweil, Therapie, Behandlung, Rehabilitation, Alltagskompetenz, Motorik, Bewegungstherapie",
    },
    neurologieGeriatrie: {
        title: "Neurologie & Geriatrie - Ergotherapie Rottweil | Spezialisierte Behandlung",
        description: "Spezialisierte neurologische und geriatrische Ergotherapie in Rottweil. ✓ Schlaganfall ✓ Parkinson ✓ Demenz ✓ Altersbedingte Einschränkungen",
        ogTitle: "Neurologie & Geriatrie - Spezialisierte Ergotherapie in Rottweil",
        ogDescription: "Professionelle Behandlung neurologischer und geriatrischer Erkrankungen. Individuelle Therapiekonzepte für mehr Lebensqualität und Selbstständigkeit.",
        keywords: "Neurologie, Geriatrie, Schlaganfall, Parkinson, Demenz, Rottweil, Ergotherapie, Rehabilitation, Senioren",
    }
};

export const COMMON_SEO_TAGS = {
    author: "Ergotherapie Rottweil",
    robots: "index, follow",
    language: "de",
    geoRegion: "DE-BW",
    geoPlacename: "Rottweil",
    baseUrl: "https://ergorw.de",
    image: "/images/tree.jpeg"
};
