import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

interface PrimaryButtonProps {
  to?: string;
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  periodic?: boolean;
}

export const PrimaryButton = ({
  to,
  href,
  onClick,
  children,
  className = "",
  periodic = false,
}: PrimaryButtonProps) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (!periodic) return;

    const interval = setInterval(() => {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 2000);
    }, 30000);

    return () => clearInterval(interval);
  }, [periodic]);

  const baseClassName = `
    inline-flex items-center justify-center
    rounded-lg relative overflow-hidden
    px-8 py-3 text-base font-medium tracking-wide text-white 
    transition-all duration-300 ease-in-out
    shadow-[0_2px_8px_-1px_rgba(139,211,230,0.3)] dark:shadow-[0_2px_8px_-1px_rgba(139,211,230,0.1)]
    hover:shadow-[0_4px_20px_-2px_rgba(139,211,230,0.5)] dark:hover:shadow-[0_4px_20px_-2px_rgba(139,211,230,0.2)]
    active:scale-[0.98]
    ${animate ? "animate-gentle-pulse" : ""}
    group
  `;

  const content = (
    <>
      {/* Gradient background */}
      <div
        className="absolute inset-0 bg-gradient-to-r from-ergo-400 via-ergo-500 to-ergo-400 
        dark:from-ergo-500 dark:via-ergo-600 dark:to-ergo-500
        group-hover:scale-[1.02] transition-transform duration-300"
      />

      {/* Shine effect */}
      <div
        className="absolute inset-0 opacity-0 group-hover:opacity-100 
        transition-opacity duration-300
        bg-gradient-to-r from-transparent via-white/10 to-transparent
        translate-x-[-100%] group-hover:translate-x-[100%] 
        transition-transform duration-1000"
      />

      {/* Button text */}
      <span className="relative text-white dark:text-white">{children}</span>
    </>
  );

  // External link
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
        className={`${baseClassName} ${className}`}
      >
        {content}
      </a>
    );
  }

  // Internal route
  if (to) {
    return (
      <Link to={to} onClick={onClick} className={`${baseClassName} ${className}`}>
        {content}
      </Link>
    );
  }

  // Regular button
  return (
    <button onClick={onClick} className={`${baseClassName} ${className}`}>
      {content}
    </button>
  );
};
