import React from "react";

export default function Datenschutzerklärung() {
  return (
    <>
      <div className="text-left">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          Datenschutzerklärung
        </h1>
        <p className="mt-4 text-sm text-gray-700 dark:text-gray-300 leading-6">Alles, was Sie wissen sollten:</p>

        {/* § 1 Information über die Erhebung personenbezogener Daten */}
        <h2 className="text-2xl mb-3 font-bold mt-6 text-gray-900 dark:text-white">
          Information über die Erhebung personenbezogener Daten
        </h2>
        <p className="mt-4 text-sm text-gray-700 dark:text-gray-300 leading-6">
          (1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website.
          Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z.B. Name, Adresse,
          E-Mail-Adressen, Nutzerverhalten. Hinsichtlich der in dieser Erklärung verwendeten Begriffe verweisen wir auf
          die Definitionen in Art. 4 Datenschutzgrundverordnung (DS-GVO).
        </p>
        <p className="text-sm text-gray-700 dark:text-gray-300 leading-6">
          (2) Verantwortlicher gem. Art. 4 Abs. 7 DS-GVO ist Lisa Spreitzer, Auf dem Wall 29, 78628 Rottweil,
          info@ergorw.de.
        </p>
        <p className="text-sm text-gray-700 dark:text-gray-300 leading-6">
          (3) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre
          Daten für werbliche Zwecke nutzen möchten, werden wir Sie unten stehend im Detail über die jeweiligen Vorgänge
          informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
        </p>

        {/* § 2 Ihre Rechte */}
        <h2 className="text-2xl mb-3 font-bold mt-6 text-gray-900 dark:text-white">§ 2 Ihre Rechte</h2>
        <p className="mt-4 text-sm text-gray-700 dark:text-gray-300 leading-6">
          (1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personell bezogenen Daten:
        </p>
        <ul className="list-disc list-inside text-sm text-gray-700 dark:text-gray-300 leading-6">
          <li>
            Recht auf Auskunft und auf Bestätigung, ob betreffende Daten erarbeitet werden sowie weitere Informationen
            und Kopie der Daten, Art. 15 DS-GVO
          </li>
          <li>Recht auf Berichtigung, Vervollständigung oder Löschung, Art. 16-17 DS-GVO</li>
          <li>Recht auf Einschränkung der Verarbeitung, Art. 18 DS-GVO</li>
          <li>Recht auf Datenübertragbarkeit, Art. 20 DS-GVO</li>
          <li>Recht auf Widerspruch gegen die Verarbeitung, Art. 21 DS-GVO</li>
          <li>Beschwerderecht bei der zuständigen Aufsichtsbehörde einzureichen, Art. 77 DS-GVO</li>
        </ul>
        <p className="text-sm text-gray-700 dark:text-gray-300 leading-6">
          (2) Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DS-GVO gelöscht oder in ihrer
          Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden
          die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und
          der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sind die Daten für andere und
          gesetzlich zulässige Zwecke erforderlich (wie z.B. aus handelsrechtlichen und steuerrechtlichen Gründen), wird
          deren Verarbeitung eingeschränkt, d. h. wir sperren die Daten und verarbeiten sie nicht für andere Zwecke.
          Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung insbesondere für sechs Jahre gemäß § 257 Abs. 1
          Handelsgesetzbuch sowie für zehn Jahre gemäß § 147 Abs. 1 Abgabenordnung. Was die Löschung der Daten angeht,
          die nicht bei uns gespeichert werden, können Sie bei dem entsprechenden Angebot nachsehen.
        </p>

        {/* § 3 Erhebung personenbezogener Daten bei Besuch unserer Website */}
        <h2 className="text-2xl mb-3 font-bold mt-6 text-gray-900 dark:text-white">
          Erhebung personenbezogener Daten bei Besuch unserer Website
        </h2>
        <p className="mt-4 text-sm text-gray-700 dark:text-gray-300 leading-6">
          (1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns
          anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren
          Server bzw. den Server, auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles) übermittelt. Wenn Sie
          unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind,
          um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:
        </p>
        {/* Add the list of data here */}
        <ul className="list-disc list-inside text-sm text-gray-700 dark:text-gray-300">
          <li>IP-Adresse</li>
          <li>Datum und Uhrzeit der Anfrage</li>
          <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
          <li>Inhalt der Anforderung (konkrete Seite)</li>
          <li>Zugriffsstatus/HTTP-Statuscode</li>
          <li>jeweils übertragene Datenmenge</li>
          <li>Meldung über erfolgreichen Abruf</li>
          <li>Referrer URL der zuvor besuchten Website</li>
          <li>die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden</li>
          <li>anfragender Provider</li>
          <li>Browsertyp nebst Version</li>
          <li>Betriebssystem und dessen Oberfläche</li>
          <li>Sprache und Version der Browsersoftware</li>
          <li>
            sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere
            informationstechnologischen Systeme dienen
          </li>
        </ul>
        <p className="text-sm text-gray-700 dark:text-gray-300">
          Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Die Speicherung der anonymen Daten der Server-Logfiles
          erfolgt getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten.
        </p>
        <p className="text-sm text-gray-700 dark:text-gray-300">
          (2) Logfile-Informationen werden aus Sicherheitsgründen für die Dauer von maximal drei Monaten gespeichert und
          danach gelöscht. Sofern die Daten, zu Beweiszwecken erforderlich sind, werden bis zur endgültigen Klärung des
          jeweiligen Vorfalls nicht gelöscht.
        </p>
        <p className="text-sm text-gray-700 dark:text-gray-300">
          (3) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner
          gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen
          verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier
          durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren
          Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu
          machen.
        </p>

        {/* § 6 Kontaktaufnahme per Kontaktformular und E-Mail */}
        <h2 className="text-2xl mb-3 font-bold mt-6 text-gray-900 dark:text-white">
          Kontaktaufnahme per Kontaktformular und E-Mail
        </h2>
        <p className="text-sm text-gray-700 dark:text-gray-300">
          (1) Sie können uns über unser Kontaktformular und per E-Mail kontaktieren. Für die Bearbeitung der
          Kontaktanfrage und deren Abwicklung werden die Daten aufgrund von Art. 6 Abs. 1 lit. b DS-GVO verarbeitet.
        </p>
        <p className="text-sm text-gray-700 dark:text-gray-300">
          (2) Bei Ihrer Kontaktaufnahme werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse und Ihr Name) von
          uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir,
          nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche
          Aufbewahrungspflichten bestehen.
        </p>
        <p className="text-sm text-gray-700 dark:text-gray-300 leading-6">
          {/* Add information about email sending here */}
          Wenn Sie uns über das Kontaktformular kontaktieren, werden Ihre Angaben über EmailJS.com an unser E-Mail-Konto
          weitergeleitet. EmailJS.com verarbeitet Ihre Daten gemäß den geltenden Datenschutzbestimmungen und speichert
          sie nicht länger als nötig.
        </p>
      </div>
    </>
  );
}
