export type Svg = {
  classNames?: string;
};

export const LogoSvg: React.FC<Svg> = ({ classNames }) => (
  <svg
    width="1014"
    className={classNames}
    height="1021"
    viewBox="0 0 1014 1021"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M480.164 0.791992C373.391 5.80399 268.862 46.5814 186.523 114.615C103.402 182.647 42.6257 277.568 16.207 381.732C-14.181 499.263 -1.65037 627.599 52.2336 736.461C104.134 842.817 194.303 929.908 302.591 977.631C422.052 1030.83 562.503 1034.7 684.627 988.02C763.416 958.519 833.694 908.188 888.987 845.011C883.503 832.949 870.503 828.72 858.547 834.045C824.662 848.3 795.683 872.215 761.483 885.997C717.208 904.22 668.232 913.828 620.46 906.673C602.394 903.855 583.179 901.504 568.299 890.069C605.11 881.193 642.231 892.681 679.198 893.047C688.127 892.576 701.91 888.816 701.18 877.435C678.31 864.956 652.778 858.325 628.134 850.388C585.32 838.328 540.731 826.893 495.934 831.644C457.558 836.239 429.728 865.321 398.192 884.483C373.547 900.095 343.003 889.131 319.559 876.703C232.156 832.949 157.703 761.472 116.142 672.501C66.8523 568.965 64.0857 445.901 103.402 338.761C143.762 226.975 229.284 133.149 335.276 79.9987C432.287 30.344 546.527 15.672 652.935 38.7494C710.891 50.3947 764.67 75.9786 815.263 105.843C728.112 37.9239 618.176 0 507.723 0C498.536 0 489.344 0.262659 480.164 0.791992Z" />
    <path d="M456.043 389.981C496.455 442.507 520.368 509.599 511.753 576.328C431.136 512.263 322.797 497.277 222.915 505.892C329.951 518.163 444.555 560.977 503.295 656.995C487.317 711.504 460.376 762.463 449.464 818.383C507.367 791.389 572.788 808.148 631.319 823.76C652.571 739.02 650.951 649.477 632.52 564.369C643.067 548.184 655.911 533.408 671.471 521.869C731.671 476.236 807.064 458.067 880.111 444.961C784.667 438.748 688.492 458.171 600.827 495.659C544.332 403.4 460.847 319.183 352.976 292.292C391.403 320.175 427.744 351.607 456.043 389.981Z" />
    <path d="M346.294 122.499C380.596 110.645 414.848 97.9067 450.979 92.7894C559.84 74.6201 675.595 99.9427 765.61 164.112C836.931 214.027 891.962 286.757 920.575 368.993C941.564 368.993 962.083 374.213 980.932 383.455C958.376 293.597 904.18 212.669 830.77 156.384C754.368 96.8786 657.728 66.2653 561.254 66.2666C486.311 66.2679 411.464 84.7427 346.294 122.499Z" />
    <path d="M203.544 232.979C215.031 265.403 234.715 299.081 268.705 311.507C290.999 320.331 315.227 315.109 337.364 308.529C329.899 273.391 291.208 263.001 261.083 255.691C291.104 254.229 320.656 261.852 347.598 274.801C343.578 253.081 335.276 228.593 312.825 219.823C302.163 215.769 291.253 214.1 280.289 214.1C254.1 214.101 227.614 223.631 203.544 232.979Z" />
    <path d="M474.16 298.297C469.356 316.153 480.843 331.817 491.234 344.975C496.664 322.471 506.531 301.377 520.472 282.895C511.022 305.764 501.206 332.183 515.566 355.364C531.803 345.549 547.415 332.235 552.635 313.229C561.146 286.392 549.032 259.295 536.555 235.955C510.761 250.052 482.148 268.119 474.16 298.297Z" />
    <path d="M319.664 341.841C302.016 366.956 305.567 399.903 310.214 428.567C336.528 424.128 365.924 416.297 381.431 392.54C392.396 376.72 392.552 356.879 391.352 338.448C364.724 340.067 349.895 363.875 338.303 384.812C343.108 362.884 354.124 343.147 367.595 325.395C367.179 325.393 366.76 325.395 366.34 325.395C349.542 325.395 330.055 326.563 319.664 341.844" />
    <path d="M671.522 400.893C663.848 413.215 670.425 427.261 674.184 439.687C683.112 426.217 692.826 413.164 704.52 401.887C696.062 417.655 685.724 434.205 691.103 453.001C705.252 447.311 720.811 440.68 728.328 426.425C738.875 408.047 734.437 386.013 730.573 366.381C708.749 372.647 683.583 379.8 671.522 400.893Z" />
    <path d="M923.968 396.352C903.711 397.813 890.97 415.096 881.103 430.811C907.991 428.409 934.882 431.908 960.1 441.568C932.112 438.173 898.331 435.876 879.066 460.781C895.722 475.505 916.868 487.096 939.84 484.955C970.282 483.389 994.142 461.512 1013.41 439.949C989.711 419.04 961.888 396.184 928.844 396.185C927.234 396.185 925.606 396.24 923.968 396.352Z" />
    <path d="M115.88 470.753C124.495 494.772 141.151 519.103 166.944 526.412C183.13 531.685 199.836 526.412 215.658 522.287C209.602 500.149 187.044 491.952 167.206 486.573C162.715 486.052 164.28 481.509 164.436 478.481L168.196 484.799C186.888 485.373 204.85 490.699 222.654 496.129C217.432 480.465 210.646 461.407 192.683 456.813C186.178 455.125 179.547 454.359 172.898 454.359C153.027 454.36 133.015 461.208 115.88 470.753Z" />
    <path d="M861.783 518.633C852.072 501.925 840.063 484.641 819.648 481.092C818.03 497.173 818.03 515.239 829.36 528.135C842.36 545.052 864.239 549.96 884.131 553.353C886.063 530.015 890.396 503.021 874.732 483.18C866.275 472.216 851.29 472.58 838.968 470.701C847.947 486 856.302 501.715 861.783 518.633Z" />
    <path d="M219.521 539.1C205.945 558.417 209.967 583.376 211.741 605.409C231.635 601.963 253.772 596.793 266.095 579.199C275.44 567.295 274.813 551.527 275.493 537.272C254.243 536.593 243.329 555.859 233.461 571.419C238.683 555.024 246.723 539.831 256.279 525.629C243.383 526.985 227.615 527.039 219.521 539.1Z" />
    <path d="M368.327 609.272C354.542 631.88 360.651 659.815 368.848 683.311C393.179 673.441 420.276 662.635 434.27 638.876C442.728 623.736 434.531 607.08 426.96 593.557C417.667 611.884 406.023 628.905 391.299 643.315C402.734 624.676 413.96 603.948 408.059 581.287C392.135 585.881 376.628 594.392 368.327 609.272Z" />
    <path d="M357.884 235.589C359.136 245.407 360.338 265.403 372.608 265.716C382.424 246.555 394.171 228.541 409.939 213.713C398.399 233.711 381.744 257.989 392.971 281.588C407.538 284.095 419.755 271.512 429.102 261.852C451.292 235.695 443.772 198.309 433.487 168.705C402.316 180.663 361.747 197.632 357.884 235.589Z" />
    <path d="M599.94 262.375C590.019 281.067 597.799 302.579 608.346 319.129C617.796 297.2 630.588 276.785 648.079 260.339C635.915 282.739 620.46 309.209 634.139 334.688C652.36 325.709 671.679 314.587 679.928 294.904C692.826 267.752 684.784 237.052 674.968 210.371C646.512 221.023 615.29 234.44 599.94 262.375Z" />
    <path d="M801.009 368.313C791.244 386.953 795.579 408.935 803.201 427.575C825.965 416.976 833.12 392.279 839.123 370.088C838.915 391.495 834.581 412.485 827.48 432.639C841.473 427.888 857.92 422.719 864.239 407.889C875.463 380.584 862.984 351.187 852.072 325.916C832.335 335.941 810.929 347.272 801.009 368.313Z" />
    <path d="M558.223 392.749C552.531 405.488 560.571 418.02 565.479 429.507C573.468 414.156 583.023 399.692 594.406 386.64C585.007 403.243 577.331 421.623 582.71 441.045C596.023 435.145 610.224 427.836 616.647 413.843C627.299 393.793 621.138 370.507 613.41 350.665C592.474 360.064 567.672 369.88 558.223 392.749Z" />
    <path d="M237.482 473.312C242.703 485.269 256.435 488.192 267.139 493.152C262.544 476.811 258.575 460.207 258.053 443.133C264.007 460.625 268.862 482.397 289.591 487.461C292.67 473.679 297.735 459.007 292.356 445.117C285.047 424.441 265.364 411.389 245.523 404.287C238.631 426.164 226.727 450.704 237.482 473.312Z" />
    <path d="M399.34 421.099C387.696 436.816 375.898 455.612 379.395 476.027C381.692 489.551 393.911 497.381 403.622 505.579C404.3 487.201 405.45 468.873 409.103 450.809C411.087 470.909 410.514 493.675 427.639 507.773C435.054 496.339 444.139 484.903 445.496 470.805C448.159 446.371 433.226 423.868 415.37 408.727C415.264 408.727 415.16 408.727 415.056 408.727C407.486 408.727 403.718 416.161 399.34 421.104" />
    <path d="M944.07 511.531C943.182 649.423 871.391 782.2 760.754 863.337C899.69 796.871 989.808 646.553 993.671 493.831C978.06 502.133 961.404 508.189 944.07 511.531Z" />
    <path d="M741.643 580.504C732.036 559.044 716.999 536.228 691.259 534.295C690.736 551.421 689.796 570.009 700.187 584.785C714.859 608.489 743.679 616.06 769.472 620.395C771.562 592.409 776.939 560.141 758.77 536.123C748.379 522.497 729.947 522.391 714.492 521.452C726.919 539.36 738.25 558.523 741.643 580.504Z" />
  </svg>
);
