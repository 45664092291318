import React, { useState, FormEvent } from "react";
import { sendEmail } from "../services/mail-service";
import { PATH_FAQ, PATH_PRIVACY_POLICY } from "../utils/constants";
import { SEOHelmet } from "../components/common/SEOHelmet";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { LogoSvg } from "../components/svgs/LogoSvg";

export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [reason, setReason] = useState("");
  const [birthday, setBirthday] = useState("");
  const [hasPrescription, setHasPrescription] = useState(false);
  const [needsHomeVisit, setNeedsHomeVisit] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const validateForm = () => {
    if (!name.trim()) {
      setError("Bitte geben Sie Ihren Namen ein.");
      return false;
    }
    if (!email.trim()) {
      setError("Bitte geben Sie Ihre E-Mail-Adresse ein.");
      return false;
    }
    if (!validateEmail(email)) {
      setError("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
      return false;
    }
    if (!message.trim()) {
      setError("Bitte beschreiben Sie Ihr Anliegen.");
      return false;
    }
    if (message.trim().length < 50) {
      setError("Ihre Nachricht sollte mindestens 50 Zeichen lang sein.");
      return false;
    }
    return true;
  };

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setError(null);

    if (!validateForm()) {
      return;
    }

    setSending(true);
    try {
      await sendEmail(name, reason, message, email, phone, birthday, hasPrescription, needsHomeVisit);
      setSending(false);
      setSuccess(true);
      setError(null);

      // Reset form
      setName("");
      setEmail("");
      setPhone("");
      setReason("");
      setBirthday("");
      setHasPrescription(false);
      setNeedsHomeVisit(false);
      setMessage("");
      setTouched({});
    } catch (error) {
      console.error(error);
      setError("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
      setSending(false);
    }
  }

  return (
    <>
      <SEOHelmet page="contact" />

      <main className="space-y-16">
        {/* Contact Form Section */}
        <section className="mx-auto text-center max-w-2xl" aria-labelledby="contact-heading">
          <div className="flex flex-col items-center">
            <LogoSvg
              classNames="h-16 w-auto fill-current text-ergo-500 dark:text-ergo-400 mb-8"
              aria-label="Ergotherapie Rottweil Logo"
            />
            <h1
              id="contact-heading"
              className="text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-12 drop-shadow-sm"
            >
              Kontakt für Erstgespräch
            </h1>
            <div className="prose prose-lg max-w-none space-y-8 dark:prose-invert">
              <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                Bei Interesse an einem Termin füllen Sie bitte dieses Kontaktformular aus. In einem ca. 15-minütigen
                kostenlosen Vorgespräch klären wir telefonisch erste Fragen.
              </p>
              <div className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed border-t border-gray-100 dark:border-gray-700/60 pt-8">
                <a
                  href={PATH_FAQ}
                  className="text-ergo-600 hover:text-ergo-500 dark:text-ergo-400 dark:hover:text-ergo-300 no-underline font-medium"
                >
                  Hier finden Sie gängige Fragen und Antworten →
                </a>
              </div>
            </div>
          </div>
        </section>

        {success ? (
          <section
            className="mx-auto max-w-2xl text-center bg-green-50 dark:bg-green-900/20 p-8 rounded-2xl border border-green-100 dark:border-green-900/30"
            aria-label="Erfolgsmeldung"
          >
            <div className="flex items-center justify-center mb-4">
              <svg
                className="h-12 w-12 text-green-500 dark:text-green-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Nachricht erfolgreich versendet
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300" role="alert">
              Ich werde mich so bald wie möglich bei Ihnen melden.
            </p>
          </section>
        ) : (
          <section aria-label="Kontaktformular" className="mx-auto">
            <form onSubmit={handleSubmit} className="space-y-12" noValidate>
              <div className="bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm rounded-2xl p-8 sm:p-12 shadow-sm border border-gray-100 dark:border-gray-700/60 max-w-4xl mx-auto">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Name */}
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Name <span className="text-ergo-600 dark:text-ergo-400">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => setName(e.target.value)}
                        onBlur={() => handleBlur("name")}
                        value={name}
                        required
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        aria-required="true"
                        className={`block w-full rounded-md border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset 
                          ${
                            touched.name && !name.trim()
                              ? "ring-red-300 dark:ring-red-500"
                              : "ring-gray-300 dark:ring-gray-700"
                          } 
                          placeholder:text-gray-400 dark:placeholder:text-gray-500 
                          focus:ring-2 focus:ring-inset focus:ring-ergo-600 dark:focus:ring-ergo-400 
                          bg-white dark:bg-gray-800 sm:text-sm`}
                      />
                      {touched.name && !name.trim() && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-400">Bitte geben Sie Ihren Namen ein</p>
                      )}
                    </div>
                  </div>

                  {/* Email */}
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Email <span className="text-ergo-600 dark:text-ergo-400">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => handleBlur("email")}
                        value={email}
                        required
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        aria-required="true"
                        className={`block w-full rounded-md border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset 
                          ${
                            touched.email && (!email.trim() || !validateEmail(email))
                              ? "ring-red-300 dark:ring-red-500"
                              : "ring-gray-300 dark:ring-gray-700"
                          } 
                          placeholder:text-gray-400 dark:placeholder:text-gray-500 
                          focus:ring-2 focus:ring-inset focus:ring-ergo-600 dark:focus:ring-ergo-400 
                          bg-white dark:bg-gray-800 sm:text-sm`}
                      />
                      {touched.email && !email.trim() && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                          Bitte geben Sie Ihre E-Mail-Adresse ein
                        </p>
                      )}
                      {touched.email && email.trim() && !validateEmail(email) && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                          Bitte geben Sie eine gültige E-Mail-Adresse ein
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Phone */}
                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Telefonnummer
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        type="tel"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        className="block w-full rounded-md border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset 
                          ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 dark:placeholder:text-gray-500 
                          focus:ring-2 focus:ring-inset focus:ring-ergo-600 dark:focus:ring-ergo-400 
                          bg-white dark:bg-gray-800 sm:text-sm"
                      />
                    </div>
                  </div>

                  {/* Birthday */}
                  <div>
                    <label htmlFor="birthday" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Geburtstag
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => setBirthday(e.target.value)}
                        value={birthday}
                        type="date"
                        name="birthday"
                        id="birthday"
                        autoComplete="bday"
                        className="block w-full rounded-md border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset 
                          ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 dark:placeholder:text-gray-500 
                          focus:ring-2 focus:ring-inset focus:ring-ergo-600 dark:focus:ring-ergo-400 
                          bg-white dark:bg-gray-800 sm:text-sm"
                      />
                    </div>
                  </div>

                  {/* Prescription Checkbox */}
                  <div className="flex items-center pt-8">
                    <div className="flex h-6 items-center">
                      <input
                        onChange={(e) => setHasPrescription(e.target.checked)}
                        checked={hasPrescription}
                        type="checkbox"
                        name="hasPrescription"
                        id="hasPrescription"
                        className="h-5 w-5 rounded border-gray-300 dark:border-gray-600 text-ergo-600 dark:text-ergo-400 
                          focus:ring-0 dark:focus:ring-0 cursor-pointer bg-white dark:bg-gray-800"
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor="hasPrescription"
                        className="text-base font-medium text-gray-700 dark:text-gray-300 cursor-pointer"
                      >
                        Rezept bereits vorhanden
                      </label>
                    </div>
                  </div>

                  {/* Home Visit Checkbox */}
                  <div className="flex items-center pt-8">
                    <div className="flex h-6 items-center">
                      <input
                        onChange={(e) => setNeedsHomeVisit(e.target.checked)}
                        checked={needsHomeVisit}
                        type="checkbox"
                        name="needsHomeVisit"
                        id="needsHomeVisit"
                        className="h-5 w-5 rounded border-gray-300 dark:border-gray-600 text-ergo-600 dark:text-ergo-400 
                          focus:ring-0 dark:focus:ring-0 cursor-pointer bg-white dark:bg-gray-800"
                      />
                    </div>
                    <div className="ml-3">
                      <label
                        htmlFor="needsHomeVisit"
                        className="text-base font-medium text-gray-700 dark:text-gray-300 cursor-pointer"
                      >
                        Hausbesuch gewünscht
                      </label>
                    </div>
                  </div>

                  {/* Treatment Reason - Full Width */}
                  <div className="lg:col-span-3">
                    <label htmlFor="reason" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Grund für die Behandlung
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => setReason(e.target.value)}
                        value={reason}
                        type="text"
                        name="reason"
                        id="reason"
                        className="block w-full rounded-md border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset 
                          ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 dark:placeholder:text-gray-500 
                          focus:ring-2 focus:ring-inset focus:ring-ergo-600 dark:focus:ring-ergo-400 
                          bg-white dark:bg-gray-800 sm:text-sm"
                      />
                    </div>
                  </div>

                  {/* Message - Full Width */}
                  <div className="lg:col-span-3">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Ihre Nachricht <span className="text-ergo-600 dark:text-ergo-400">*</span>
                    </label>
                    <div className="mt-2">
                      <textarea
                        onChange={(e) => setMessage(e.target.value)}
                        onBlur={() => handleBlur("message")}
                        value={message}
                        name="message"
                        id="message"
                        rows={6}
                        minLength={50}
                        required
                        aria-required="true"
                        placeholder="Beschreiben Sie bitte kurz Ihr Anliegen..."
                        className={`block w-full rounded-md border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset 
                          ${
                            touched.message && (!message.trim() || message.trim().length < 50)
                              ? "ring-red-300 dark:ring-red-500"
                              : "ring-gray-300 dark:ring-gray-700"
                          } 
                          placeholder:text-gray-400 dark:placeholder:text-gray-500 
                          focus:ring-2 focus:ring-inset focus:ring-ergo-600 dark:focus:ring-ergo-400 
                          bg-white dark:bg-gray-800 sm:text-sm`}
                      />
                      {touched.message && !message.trim() && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                          Bitte beschreiben Sie Ihr Anliegen
                        </p>
                      )}
                      {touched.message && message.trim() && message.trim().length < 50 && (
                        <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                          Ihre Nachricht sollte mindestens 50 Zeichen lang sein
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {error && (
                  <div className="mt-6 rounded-md bg-red-50 dark:bg-red-900/20 p-4" role="alert">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-red-400 dark:text-red-300" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700 dark:text-red-300">{error}</p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-8 space-y-4">
                  <button
                    type="submit"
                    disabled={sending}
                    className="group relative w-full rounded-full bg-ergo-600 dark:bg-ergo-500 px-6 py-3 text-center text-sm font-semibold text-white shadow-sm 
                      hover:bg-ergo-500 dark:hover:bg-ergo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ergo-600 dark:focus-visible:outline-ergo-400 
                      transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed overflow-hidden"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <PaperAirplaneIcon
                        className="h-5 w-5 text-ergo-300 group-hover:text-ergo-200 transition-colors duration-300"
                        aria-hidden="true"
                      />
                    </span>
                    {sending ? (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Wird gesendet...
                      </div>
                    ) : (
                      <span className="pl-6">Nachricht senden</span>
                    )}
                  </button>

                  <p className="text-sm text-gray-500 dark:text-gray-400 text-center">
                    Mit dem Absenden des Formulars stimmen Sie unserer{" "}
                    <a
                      href={PATH_PRIVACY_POLICY}
                      className="text-ergo-600 hover:text-ergo-500 dark:text-ergo-400 dark:hover:text-ergo-300"
                    >
                      Datenschutzerklärung
                    </a>{" "}
                    zu.
                  </p>
                </div>
              </div>
            </form>
          </section>
        )}
      </main>
    </>
  );
}
