import React from "react";
import { Testimonial, TestimonialsComponent } from "../components/common/testimonial/TestimonialComponent";
import { SEOHelmet } from "../components/common/SEOHelmet";

type Section = {
  title: string;
  content: string[];
  img?: string;
  ratio?: string;
};

const data: { sections: Section[]; testimonials: Testimonial[] } = {
  sections: [
    {
      title: "Pädiatrie",
      content: [
        "Aufmerksamkeit- und Konzentrationsstörungen",
        "ADS,ADHS",
        "Verhaltensschwierigkeiten z.B. sozialer Rückzug, aggressives Verhalten",
        "Schwierigkeiten mit dem Selbstbewusstsein/Selbstwert",
        "Ängste",
        "Schwierigkeiten im schulischen Bereich",
        "Konfliktsituationen zu Hause oder/und in der Schule",
      ],
      img: process.env.PUBLIC_URL + "/images/paediatrics.jpg",
      ratio: "aspect-[3/4]",
    },
    {
      title: "Was könnte eine Behandlung beinhalten?",
      content: [
        "Erstellen von Tages-/Wochenplänen",
        "Aufbau des Selbstbewusstseins/Selbstvertrauens",
        "Kommunikationstraining",
        "Soziales Kompetenztraining",
        "Erarbeiten von Interessen/Hobbys",
        "Verbesserung der Selbst- und Fremdwahrnehmung",
        "Verbesserung der Körperwahrnehmung",
        "Stressmanagement",
        "Verbesserung der Motivation",
        "Verbesserung der sozio-emotionalen Fähigkeiten",
        "Verbesserung von kognitiven Funktionen (z.B. Konzentrations- und Aufmerksamkeitstraining)",
        "Umgang mit Konfliktsituationen erlernen",
        "Umgang mit Ängsten",
      ],
    },
  ],
  testimonials: [
    {
      quote:
        "Meine Tochter hat im Verlauf der Ergotherapie gelernt, sich Ängsten zu stellen. Sie ist heute in der Lage alleine einkaufen zu gehen, ohne in Panik aus dem Laden zu rennen.",
    },
    {
      quote:
        "Durch die Ergotherapie habe ich viele wertvolle Tipps erhalten, um die Hausaufgaben mit meinem Sohn besser bewältigen zu können.",
    },
  ],
};

export default function PediatricsPage() {
  return (
    <>
      <SEOHelmet page="paediatrie" />

      <main className="space-y-16">
        {data.sections.map((section, index) => (
          <article key={index}>
            {index === 0 ? (
              // First section with image
              <section className="grid lg:grid-cols-2 gap-12 items-start" aria-labelledby="main-heading">
                <div>
                  <h1
                    id="main-heading"
                    className="text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-12 drop-shadow-sm"
                  >
                    Pädiatrische Ergotherapie
                  </h1>
                  <h2 className="sr-only">Behandlungsschwerpunkte</h2>
                  <ul
                    className="list-disc marker:text-ergo-400 dark:marker:text-ergo-500 list-inside space-y-3 mb-8 text-lg text-gray-600 dark:text-gray-300"
                    aria-label="Behandlungsschwerpunkte in der Pädiatrie"
                  >
                    {section.content.map((item, index) => (
                      <li key={index} className="text-gray-600 dark:text-gray-300 leading-relaxed">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                {section.img && (
                  <aside
                    className="lg:sticky lg:top-8"
                    aria-label="Bildliche Darstellung der pädiatrischen Ergotherapie"
                  >
                    <img
                      src={section.img}
                      alt="Pädiatrische Ergotherapie - Kindgerechte Behandlung"
                      className="w-64 md:w-96 lg:w-124 rounded-2xl object-cover shadow-md max-h-[600px] aspect-[3/4]"
                    />
                  </aside>
                )}
              </section>
            ) : (
              // Treatment details section
              <section aria-labelledby={`section-${index}`}>
                <h2
                  id={`section-${index}`}
                  className="text-2xl sm:text-3xl font-semibold tracking-tight text-ergo-600 dark:text-ergo-400 mb-8 drop-shadow-sm"
                >
                  {section.title}
                </h2>
                <ul
                  className="list-disc marker:text-ergo-400 dark:marker:text-ergo-500 list-inside space-y-3 mb-8 text-lg text-gray-600 dark:text-gray-300"
                  aria-label="Behandlungsinhalte und Therapiemöglichkeiten"
                >
                  {section.content.map((item, index) => (
                    <li key={index} className="text-gray-600 dark:text-gray-300 leading-relaxed">
                      {item}
                    </li>
                  ))}
                </ul>
              </section>
            )}
          </article>
        ))}

        {/* Testimonials Section */}
        <section aria-label="Erfahrungsberichte">
          <h2 className="sr-only">Erfahrungsberichte unserer Klienten</h2>
          <TestimonialsComponent testimonials={data.testimonials} />
        </section>
      </main>
    </>
  );
}
