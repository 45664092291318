import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PublicApp } from "./PublicApp";
import { ThemeProvider } from "../contexts/theme-context";

export default function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <Router>
          <div className="min-h-screen bg-ergo-50 dark:bg-gray-900">
            <Routes>
              <Route path="/*" element={<PublicApp />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}
