import React from "react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

export const TreatmentInfoBanner = () => {
  return (
    <div className="relative">
      <div className="px-6 sm:px-8 lg:px-12">
        <div className="relative mx-auto">
          <div
            className="relative backdrop-blur-sm bg-white/80 dark:bg-gray-900/80 rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.04)] dark:shadow-[0_8px_30px_rgb(0,0,0,0.2)]
            border border-ergo-100/60 dark:border-gray-700/60 p-8 sm:p-10"
          >
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-6 sm:gap-8">
              <div className="flex-shrink-0">
                <div
                  className="p-4 rounded-xl bg-ergo-100/80 dark:bg-ergo-900/80 
                  shadow-[0_8px_20px_rgb(139,211,230,0.2)] dark:shadow-[0_8px_20px_rgb(139,211,230,0.1)] transform hover:scale-105 transition-all duration-300"
                >
                  <DocumentTextIcon className="h-6 w-6 text-ergo-600 dark:text-ergo-400" />
                </div>
              </div>
              <div className="space-y-4">
                <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                  Voraussetzung für eine ergotherapeutische Behandlung als Kassenleistung ist eine Verordnung von ihrer
                  behandelnden Ärztin oder ihrem Arzt.
                </p>
                <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                  Zusätzlich bieten wir zahlreiche ergänzende und präventive Leistungen auf Selbstzahlerbasis an.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
