import { NavigationItem } from "../models/NavigationItem";

/**
 * The various paths on the website.
 */
export const PATH_START = "/"
export const PATH_UNKNOWN = "*"
export const PATH_ERGOTHERAPIE = "/ergotherapie"
export const PATH_NEUROLOGIE_GERIATRIE = "/neurologie-geriatrie"
export const PATH_PSYCHIATRIE = "/psychiatrie"
export const PATH_PEDIATRICS = "/paediatrie"
export const PATH_ABOUT = "/team"
export const PATH_TERMS_AND_CONDITIONS = "/impressum"
export const PATH_PRIVACY_POLICY = "/datenschutzerklaerung"
export const PATH_FAQ = "/faq"
export const PATH_CONTACT = "/kontakt"
export const PATH_ADDITIONAL_OFFERS = "/zusatzangebote"
/**
 *  The main navigation options for the website.
 */
export const navigationItems: NavigationItem[] = [
  { name: "Home", href: PATH_START },
  { name: "Ergotherapie", href: PATH_ERGOTHERAPIE },
  { name: "Neurologie / Geriatrie", href: PATH_NEUROLOGIE_GERIATRIE },
  { name: "Psychiatrie", href: PATH_PSYCHIATRIE },
  { name: "Pädiatrie", href: PATH_PEDIATRICS },
  { name: "Zusatzangebote", href: PATH_ADDITIONAL_OFFERS },
  { name: "Team", href: PATH_ABOUT },
]

/**
 *  The footer links for the website.
 */
export const footerLinkItems: NavigationItem[] = [
  { name: "Impressum", href: PATH_TERMS_AND_CONDITIONS },
  { name: "Datenschutzerklärung", href: PATH_PRIVACY_POLICY },
]
