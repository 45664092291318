import React from 'react';
import { QuoteSvg } from "../../svgs/QuoteSvg";

export type Testimonial = {
  quote: string;
  person?: string;
};

const TestimonialItem: React.FC<Testimonial> = ({ quote, person }) => (
  <div className="group relative backdrop-blur-sm bg-white/80 dark:bg-gray-900/80 rounded-2xl 
    shadow-[0_8px_30px_rgb(0,0,0,0.04)] dark:shadow-[0_8px_30px_rgb(0,0,0,0.2)] 
    border border-ergo-100/60 dark:border-gray-700/60 p-8
    hover:shadow-[0_8px_30px_rgb(0,0,0,0.08)] dark:hover:shadow-[0_8px_30px_rgb(0,0,0,0.3)] transition-all duration-300">
    {/* Quote Icon */}
    <div className="flex justify-center">
      <div className="p-4 rounded-xl bg-ergo-100/80 dark:bg-ergo-900/80 
        shadow-[0_8px_20px_rgb(139,211,230,0.2)] dark:shadow-[0_8px_20px_rgb(139,211,230,0.1)] transform group-hover:scale-105 transition-all duration-300">
        <QuoteSvg classNames="w-6 h-6 fill-current text-ergo-600 dark:text-ergo-400" />
      </div>
    </div>

    {/* Quote Text */}
    <blockquote className="mt-6 text-base text-gray-600 dark:text-gray-300 leading-relaxed">
      "{quote}"
    </blockquote>

    {/* Person */}
    {person && (
      <div className="mt-6 flex items-center justify-center">
        <p className="text-sm font-medium text-gray-900 dark:text-white">
          {person}
        </p>
      </div>
    )}
  </div>
);

export const TestimonialsComponent: React.FC<{ testimonials: Testimonial[] }> = ({ testimonials }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
      {testimonials.map((testimonial, index) => (
        <TestimonialItem key={index} {...testimonial} />
      ))}
    </div>
  );
};
