import React from "react";

export default function UnknownPage() {
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600"></p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-1xl">
            Diese Seite existiert nicht.
          </h1>
        </div>
      </main>
    </>
  );
}
