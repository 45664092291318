export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export function isPath(path: string, otherPath: string) {
    return decodeURIComponent(path) == decodeURIComponent(otherPath);
}

/**
 * Calculate the age of a person based on their date of birth.
 * @param date The date of birth of the person.
 * @returns The age of the person.
 */
export function calculateYears(date: string): number {
    const currentDate: Date = new Date();
    // Convert the string date to Date object
    const inputDate: Date = new Date(date);
    let years: number = currentDate.getFullYear() - inputDate.getFullYear();
    const m: number = currentDate.getMonth() - inputDate.getMonth();
    if (m < 0 || (m === 0 && currentDate.getDate() < inputDate.getDate())) {
        years--;
    }
    return years;
}
