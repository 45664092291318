import React from "react";
import { SEOHelmet } from "../components/common/SEOHelmet";
import { Testimonial, TestimonialsComponent } from "../components/common/testimonial/TestimonialComponent";

type Section = {
  title: string;
  text?: string;
  content?: string[];
  img?: string;
  ratio?: string;
};

const data: { sections: Section[]; testimonials: Testimonial[] } = {
  sections: [
    {
      title: "Psychiatrie",
      text: "Eine psychische Erkrankung kann jeden treffen. Nicht nur Erwachsene, bereits Kinder können psychische Auffälligkeiten aufweisen. Umso wichtiger ist es, diese ernst zu nehmen und entsprechend zu behandeln. Oft ist nicht nur der Leidensdruck groß, sondern auch die Scham. Die Praxis bietet den nötigen Raum, offen über bestehende Probleme zu sprechen. Auf Wünsche und Bedürfnisse des Klienten:In einzugehen. Innerhalb der Therapie werden gemeinsam Strategien und Lösungen erarbeitet, die sich am Alltag des Klienten:In orientieren.",
      img: process.env.PUBLIC_URL + "/images/psychiatry_1.jpg",
      ratio: "aspect-[3/4]",
    },
    {
      title: "Psychische Erkrankungen sind unter anderem",
      content: [
        "Depressionen",
        "Burnout",
        "Angst- und Panikstörungen",
        "Zwangsstörungen",
        "Schizophrenie",
        "Emotionale Störungen",
        "Psychische Verhaltensstörungen",
        "Persönlichkeitsstörungen",
        "Organisch bedingte psychische Störungen",
        "Belastungs- und somatoforme Störungen",
      ],
    },
    {
      title: "Eine psychisch-funktionelle Behandlung kann folgendes beinhalten:",
      content: [
        "Erarbeiten einer Tagesstrukturierung",
        "Erstellen von Tages-/Wochenplänen",
        "Aufbau des Selbstbewusstseins/Selbstvertrauens",
        "Kommunikationstraining",
        "Soziales Kompetenztraining",
        "Erarbeiten von Interessen/Hobbys",
        "Wiederaufnahme von Alltagstätigkeiten",
        "Verbesserung der Selbst- und Fremdwahrnehmung",
        "Verbesserung der Körperwahrnehmung",
        "Stressmanagement",
        "Verbesserung des Antriebs",
        "Verbesserung der Ausdauer und Belastbarkeit",
        "Verbesserung der Motivation",
        "Verbesserung der sozio-emotionalen Fähigkeiten",
        "Verbesserung von kognitiven Funktionen (z.B. Aufmerksamkeits- und Gedächtnistraining)",
        "Management für Selbstfürsorge",
        "Umgang mit Konfliktsituationen erlernen",
        "Genusstraining",
        "Umgang innerhalb der Partnerschaft",
        "Umgang mit dem Thema Sexualität",
        "Einbindung von Angehörigen/Bezugspersonen",
      ],
      img: process.env.PUBLIC_URL + "/images/psychiatry_2.jpg",
      ratio: "aspect-[3/4]",
    },
  ],
  testimonials: [
    {
      quote:
        "Früher konnte ich mich an vielem erfreuen, war im Verein sehr eingebunden und hatte einen großen Freundeskreis. Doch dann kam die Depression. Ich konnte mich immer schlechter konzentrieren, habe mich mehr und mehr zurückgezogen. Konnte morgens nicht mehr aufstehen, wollte nur noch im Bett liegen. Alles war anstrengend.",
    },
    {
      quote:
        "In der Ergotherapie habe ich gelernt, wieder die nötige Tagesstruktur zu erhalten. Ich kann nun morgens wieder aufstehen und bereits einige Alltagsverrichtungen durchführen. Es liegt noch viel Arbeit vor mir, aber ich sehe wieder Licht am Ende des Tunnels. Alleine hätte ich das nicht geschafft.",
    },
  ],
};

export default function PsychiatryPage() {
  return (
    <>
      <SEOHelmet page="psychiatrie" />

      <div className="space-y-16">
        {data.sections.map((section, index) => (
          <div key={index}>
            {index === 0 ? (
              // First section with image and text
              <div className="grid lg:grid-cols-2 gap-12 items-start">
                <div>
                  <h1 className="text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-12 drop-shadow-sm">
                    {section.title}
                  </h1>
                  <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6">{section.text}</p>
                </div>
                {section.img && (
                  <div className="lg:sticky lg:top-8">
                    <img
                      src={section.img}
                      alt="Psychiatrische Ergotherapie - Therapeutische Behandlung"
                      className={`w-64 md:w-96 lg:w-124 rounded-2xl object-cover shadow-md ${section.ratio || ""}`}
                    />
                  </div>
                )}
              </div>
            ) : (
              // Other sections
              <div className={section.img ? "grid lg:grid-cols-2 gap-12 items-start" : ""}>
                <div>
                  <h2 className="text-2xl sm:text-3xl font-semibold tracking-tight text-ergo-600 dark:text-ergo-400 mb-8 drop-shadow-sm">
                    {section.title}
                  </h2>
                  {section.content && (
                    <ul className="list-disc marker:text-ergo-400 dark:marker:text-ergo-500 list-inside space-y-3 text-lg text-gray-600 dark:text-gray-300">
                      {section.content.map((item, index) => (
                        <li key={index} className="text-gray-600 dark:text-gray-300">{item}</li>
                      ))}
                    </ul>
                  )}
                  {section.text && <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6">{section.text}</p>}
                </div>
                {section.img && (
                  <div className="lg:sticky lg:top-8">
                    <img
                      src={section.img}
                      alt="Psychiatrische Ergotherapie - Therapeutische Übungen"
                      className={`w-64 md:w-96 lg:w-124 rounded-2xl object-cover shadow-md ${section.ratio || ""}`}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}

        {/* Testimonials */}
        <div className="mt-16">
          <TestimonialsComponent testimonials={data.testimonials} />
        </div>
      </div>
    </>
  );
}
