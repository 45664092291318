import { Link } from "react-router-dom";
import {
  PATH_PRIVACY_POLICY,
  PATH_TERMS_AND_CONDITIONS,
  PATH_CONTACT,
  PATH_FAQ,
  PATH_ABOUT,
  PATH_ERGOTHERAPIE,
  PATH_NEUROLOGIE_GERIATRIE,
  PATH_PSYCHIATRIE,
  PATH_PEDIATRICS,
  PATH_ADDITIONAL_OFFERS,
} from "../../utils/constants";
import { MapPinIcon, PhoneIcon, EnvelopeIcon, ClockIcon } from "@heroicons/react/24/outline";
import { FaInstagram } from "react-icons/fa";
import { ThemeSwitcher } from "../common/ThemeSwitcher";

export const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-800/50 border-t border-gray-100 dark:border-gray-700 transition-colors duration-200 ">
      <div className="mx-auto max-w-7xl ">
        <div className="mx-auto px-6 sm:px-8 lg:px-12">
          {/* Main Footer Content */}
          <div className="py-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            {/* Column 1: About */}
            <div className="flex flex-col items-start">
              <h3 className="text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider mb-6">
                Über uns
              </h3>
              <div className="space-y-4">
                <Link
                  to={PATH_ABOUT}
                  className="block text-base text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  Team
                </Link>
                <Link
                  to={PATH_FAQ}
                  className="block text-base text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  FAQ
                </Link>
                <div>
                  <h4 className="text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">Soziale Netzwerke</h4>
                  <a
                    href="https://www.instagram.com/ergotherapie_rottweil"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-base text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                  >
                    <FaInstagram className="h-5 w-5" />
                    <span>Instagram</span>
                  </a>
                </div>
              </div>
            </div>

            {/* Column 2: Services */}
            <div className="flex flex-col items-start">
              <h3 className="text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider mb-6">
                Leistungen
              </h3>
              <div className="space-y-4">
                <Link
                  to={PATH_ERGOTHERAPIE}
                  className="block text-base text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  Ergotherapie
                </Link>
                <Link
                  to={PATH_NEUROLOGIE_GERIATRIE}
                  className="block text-base text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  Neurologie / Geriatrie
                </Link>
                <Link
                  to={PATH_PSYCHIATRIE}
                  className="block text-base text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  Psychiatrie
                </Link>
                <Link
                  to={PATH_PEDIATRICS}
                  className="block text-base text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  Pädiatrie
                </Link>
                <Link
                  to={PATH_ADDITIONAL_OFFERS}
                  className="block text-base text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  Zusatzangebote
                </Link>
              </div>
            </div>

            {/* Column 3: Contact Info */}
            <div className="flex flex-col items-start">
              <h3 className="text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider mb-6">
                Kontakt & Anfahrt
              </h3>
              <div className="space-y-4 text-base text-gray-600 dark:text-gray-300">
                <div className="flex items-center gap-3">
                  <MapPinIcon className="h-5 w-5 text-ergo-500 dark:text-ergo-400 flex-shrink-0" />
                  <div>
                    <p>Auf dem Wall 29</p>
                    <p>78628 Rottweil</p>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  <ClockIcon className="h-5 w-5 text-ergo-500 dark:text-ergo-400 flex-shrink-0" />
                  <div>
                    <p>Mo-Fr: 08.00-18.00</p>
                    <p>Sa: 07.00-17.00</p>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  <PhoneIcon className="h-5 w-5 text-ergo-500 dark:text-ergo-400 flex-shrink-0" />
                  <a
                    href="tel:+074189698293"
                    className="hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                  >
                    0741 89 69 82 93
                  </a>
                </div>

                <div className="flex items-center gap-3">
                  <EnvelopeIcon className="h-5 w-5 text-ergo-500 dark:text-ergo-400 flex-shrink-0" />
                  <a
                    href="mailto:info@ergo-rw.de"
                    className="hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                  >
                    info@ergo-rw.de
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Map Section */}
          <div className="pb-16 border-t border-gray-100 dark:border-gray-800 pt-16">
            <div className="aspect-[21/9] w-full rounded-2xl overflow-hidden bg-gray-100 dark:bg-gray-800">
              <iframe
                title="Office Location"
                className="w-full h-full border-0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2661.6776429317878!2d8.62733297688778!3d48.16862197123133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479764c9178af4d7%3A0x832f5e38c16d5c49!2sAuf%20dem%20Wall%2029%2C%2078628%20Rottweil!5e0!3m2!1sde!2sde!4v1693516317714!5m2!1sde!2sde&style=feature:all|element:geometry|color:0x212121&style=feature:all|element:labels.text.stroke|color:0x000000&style=feature:all|element:labels.text.fill|color:0x757575&style=feature:poi|element:labels.text.fill|color:0x757575&style=feature:poi.park|element:geometry|color:0x181818&style=feature:poi.park|element:labels.text.fill|color:0x616161&style=feature:road|element:geometry.fill|color:0x2c2c2c&style=feature:road|element:labels.text.fill|color:0x8a8a8a&style=feature:road.arterial|element:geometry|color:0x373737&style=feature:road.highway|element:geometry|color:0x3c3c3c&style=feature:road.highway.controlled_access|element:geometry|color:0x4e4e4e&style=feature:road.local|element:labels.text.fill|color:0x616161&style=feature:transit|element:labels.text.fill|color:0x757575&style=feature:water|element:geometry|color:0x000000&style=feature:water|element:labels.text.fill|color:0x3d3d3d"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="w-full ">
        <div className="h-full flex flex-col items-center py-8 gap-8">
          {/* Theme Switcher */}
          <div className="flex justify-center">
            <ThemeSwitcher />
          </div>

          {/* Copyright and Links */}
          <div className="w-full px-6 sm:px-8 lg:px-12">
            <div className="grid grid-cols-1 sm:grid-cols-3 items-center gap-6">
              {/* Copyright */}
              <div className="text-sm text-gray-600 dark:text-gray-400 text-center sm:text-left">
                ©2023 - 2024{" "}
                <span className="text-ergo-600 dark:text-ergo-400 font-medium ml-2">Ergotherapie Rottweil</span>
              </div>

              {/* Author - Centered */}
              <div className="text-center">
                <p className="font-display text-sm tracking-wide">
                  <span className="text-gray-900 dark:text-white font-playfair italic">Lisa Spreitzer</span>
                </p>
              </div>

              {/* Legal Links */}
              <div className="flex items-center justify-center sm:justify-end gap-8">
                <Link
                  to={PATH_TERMS_AND_CONDITIONS}
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  Impressum
                </Link>
                <Link
                  to={PATH_PRIVACY_POLICY}
                  className="text-sm text-gray-600 dark:text-gray-400 hover:text-ergo-600 dark:hover:text-ergo-400 transition-colors duration-300"
                >
                  Datenschutzerklärung
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
