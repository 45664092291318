import emailjs from 'emailjs-com';

/**
 * Sends an email using the SendGrid service
 * @param senderName the name of the sender. 
 * @param senderEmail the email of the sender.
 * @param subject the subject of the email.
 * @param message the message of the email.
 */
export async function sendEmail(
    senderName: string,
    subject: string,
    message: string,
    senderEmail: string,
    senderPhone: string,
    senderBirthday: string,
    senderHasPrescription: boolean,
    senderNeedsHomeVisit: boolean
): Promise<void> {

    const serviceId = 'service_ijd4roe';
    const templateId = 'template_quii6ct';
    const userId = 'Ik9pZ2LBgWfPwf1_J';

    try {
        await emailjs.send(serviceId, templateId, {
            from_name: senderName,
            from_email: senderEmail,
            to_name: 'Lisa',
            subject: subject,
            message: message,
            phone_number: senderPhone,
            birthday: senderBirthday,
            has_prescription: senderHasPrescription ? 'Ja' : '-',
            home_visit: senderNeedsHomeVisit ? 'Ja' : '-'
        }, userId);

        console.log('Email sent successfully');
    } catch (error) {
        console.error('Error sending email: ', error);
        throw new Error('Failed to send email: ' + error);
    }
}
