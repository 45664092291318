import React from "react";
import { SEOHelmet } from "../components/common/SEOHelmet";

type Section = {
  title: string;
  text?: string;
  content?: string[];
};

const data: { sections: Section[] } = {
  sections: [
    {
      title: "Ergotherapie",
      text: "Ergotherapie befähigt Menschen dazu, verloren gegangene Fähigkeiten wiederzuerlangen oder neue zu entdecken. Sie unterstützt und begleitet Menschen jeden Alters. Ergotherapie betrachtet den Menschen ganzheitlich. Dabei werden: der Alltag, die Freizeit, der Beruf/die Schule und nach Möglichkeit auch enge Bezugspersonen/Angehörige mit einbezogen. Ergotherapie ermöglicht es Ihnen, wieder die Dinge zu tun, die Ihnen wichtig sind. Ergotherapie bietet die Möglichkeit, Alltagstätigkeiten, die ihnen aktuell erschwert sind, wiederzuerlangen.",
    },
    {
      title: "Was uns wichtig ist",
      text: "Jeder Mensch ist individuell. Genauso individuell ist jede Therapie. Sie orientiert sich immer an den Zielen und Bedürfnissen des Klienten:In. Dabei bildet die Basis jeder Therapie eine gute therapeutische Beziehung. Empathie – Akzeptanz - Wertschätzung sind für uns selbstverständlich. Ziel ist es, Menschen ein Stück auf ihrem Lebensweg zu begleiten, sie zu befähigen, wieder selbstbestimmt leben zu können, neue Ressourcen zu entdecken oder verloren gegangene wiederzuerlangen. Den Alltag wieder meistern zu können.",
    },
    {
      title: "Ergotherapie ist",
      content: ["Klientenzentriert", "Alltagsorientiert", "Ressourcen orientiert", "Ganzheitlich orientiert"],
    },
  ],
};

export default function OccupationalTherapyPage() {
  return (
    <>
      <SEOHelmet page="ergotherapie" />

      <main className="space-y-16">
        {/* Introduction Section */}
        <article>
          <header>
            <h1
              className="text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white mb-12 drop-shadow-sm"
              id="main-heading"
            >
              Was ist Ergotherapie?
            </h1>
          </header>
          <section aria-labelledby="main-heading">
            <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6">{data.sections[0].text}</p>
          </section>
        </article>

        {/* Additional Sections */}
        {data.sections.slice(1).map((section, index) => (
          <article key={index}>
            <section aria-labelledby={`section-${index}`}>
              <h2
                id={`section-${index}`}
                className="text-2xl sm:text-3xl font-semibold tracking-tight text-ergo-600 dark:text-ergo-400 mb-8 drop-shadow-sm"
              >
                {section.title}
              </h2>
              {section.text && (
                <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed mb-6">{section.text}</p>
              )}
              {section.content && (
                <ul
                  className="list-disc marker:text-ergo-400 dark:marker:text-ergo-500 list-inside space-y-3 mb-8 text-lg text-gray-600"
                  aria-label="Grundprinzipien der Ergotherapie"
                >
                  {section.content.map((item, index) => (
                    <li key={index} className="text-gray-600 dark:text-gray-300 leading-relaxed">
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </section>
          </article>
        ))}
      </main>
    </>
  );
}
